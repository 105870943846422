import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: '50vw',
  },
  tableAaction: {
    display: 'flex',
    justifyContent: 'space-evenly'
  }
});

function CouponTable({data, applicationType = ""}) {
  // console.log({applicationType});

  const dataKey = `${applicationType.toLowerCase()}Data`;
  // console.log({dataKey, data});

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Sr No.</TableCell>
            <TableCell align="left">Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data[dataKey].map((item, index) => {
              let {id, name} = item;
              return (
                <TableRow key={id}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">{name}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CouponTable
