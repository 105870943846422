import { toast } from 'react-toastify';

const TOAST_CONFIG = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export function displayToast({toastMsg, toastType = 'info'}){
    toast[toastType](toastMsg,TOAST_CONFIG);
}