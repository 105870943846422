import React, {useState} from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {Calendar} from 'react-date-range';
import {TextField, Button, Popover} from '@material-ui/core';
import {getServerDate, getWebsiteDate} from '../utils/functions';

function DatePicker({label,helperText="",changeDate,type}) {

  const [date, setDate] = useState(new Date());

  const [anchorEl,
    setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseDate = () => {
    setAnchorEl(null);
    let val = getServerDate(date);
    changeDate(val,type);
  }

  const open = Boolean(anchorEl);
  const id = open
    ? 'simple-popover'
    : undefined;
  
  return (
    <div className="date-picker-component">
    
      <TextField
        id="outlined-basic"
        label={label}
        value={getWebsiteDate(date)}
        fullWidth
        variant="outlined"
        placeholder={label}
        helperText={helperText}
        // InputProps={{
        //   readOnly: true
        //  }}
        onClick={handleClick}/>
   
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
        <div className="evenly-spaced col-dir">
          <Calendar date={date} onChange={item => setDate(item)}
              minDate={new Date()}
          />

          <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={chooseDate}>
            Confirm
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export default DatePicker
