import axios from 'axios';
import {
    getLocalStorageKey
} from '../hooks/useLocalStorageHook';
const {BASE_WEBSITE_PATH, PRODUCTION, CORS_PATH} = require('./baseRoutes');

let BRAND_NAME = "", BASE_URL = null;

export async function hitApi(reqOptions) {
    let response;
    let {
        url,
        body,
        method
    } = reqOptions;
    method = method.toUpperCase();

    if (method === "POST") {
        response = await axios
            .post(url, body)
            .then(serverResponse => {
                let statusCode = serverResponse.status;
                if (statusCode === 200) {
                    return {
                        apiHitSucess: true,
                        body: serverResponse.data
                    };
                } else {
                    let apiErr = {};
                    apiErr.statusCode = statusCode;
                    return {
                        apiHitSucess: false,
                        error: apiErr
                    };
                }
            })
            .catch(error => {
                console.log(error);
                // reject(error);
            });
    } else {
        response = axios
            .get(url)
            .then(serverResponse => {
                let statusCode = serverResponse.status;
                if (statusCode === 200) {
                    return {
                        apiHitSucess: true,
                        body: serverResponse.data
                    };
                } else {
                    let apiErr = {};
                    apiErr.statusCode = statusCode;
                    return {
                        apiHitSucess: false,
                        error: apiErr
                    };
                }
            })
            .catch(error => {
                return {
                    apiHitSucess: false,
                    error: error
                };
            });
    }
    return response;
}

export const fetchApi = async ({
    body,
    method,
    url
}) => {
    if (!BRAND_NAME || !BASE_URL) {
        BRAND_NAME = getLocalStorageKey('brand');
        if(!BRAND_NAME){
            if(body.brandName){
                BRAND_NAME = body.brandName; 
            }
        }
        BASE_URL = BASE_WEBSITE_PATH[BRAND_NAME].BACKEND;
    }
    const finalUrl = BASE_URL + url;
    // const finalUrl = PRODUCTION ? `${CORS_PATH}${BASE_URL}${url}` :  `api/${BRAND_NAME}/${url}`;
    // const finalUrl = `api/${BRAND_NAME}/${url}`;
    // console.log({finalUrl});
    
    const headers = {
        // "Content-type": "application/json; charset=UTF-8",
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Access-Control-Allow-Origin" : "*",
        "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin, X-Requested-With, Content-Type, Accept,Authorization"
        // "brandName" : `${BRAND_NAME}`
    };
    
    try {
        let fetchBody = method === 'POST' ? {
            method,
            body: JSON.stringify(body),
             // Adding headers to the request
          headers
        } : {
            method,
            headers
        };

        // console.log({finalUrl, fetchBody});

        const res = await fetch(
            finalUrl,
            fetchBody
        );
        const result = await res.json();
        
        return result;
    } catch (error) {  
        
        console.log({error, msg : error.message});      
        return error.message;
    }
}