import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Nav from "./components/Nav";
import './styles/global.scss';
// import { ReactQueryDevtools } from 'react-query-devtools'
import { AuthProvider } from './context/Auth';

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#006CE7',
      light: '#a6d4fa',
      dark: '#648dae'
    },
    secondaryError: {
      main: '#f48fb1',
      light: '#f6a5c0',
      dark: '#aa647b'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
    <AuthProvider>
      <Nav/>
    </AuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </ThemeProvider>
  );
}

export default App;
