import React, {useState} from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRangePicker, defaultStaticRanges, createStaticRanges} from 'react-date-range';
import {subMonths, subYears} from 'date-fns';
import {isMobile} from 'react-device-detect';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SubText from './SubText';

const staticRanges = [
  ...defaultStaticRanges,
  ...createStaticRanges([
    {
      label: 'Last 3 months',
      range: () => ({
        startDate: subMonths(new Date(),3),
        endDate: new Date()
      })
    },
    {
      label: 'Last 6 months',
      range: () => ({
        startDate: subMonths(new Date(),6),
        endDate: new Date()
      })
    },
    {
      label: 'Last 9 months',
      range: () => ({
        startDate: subMonths(new Date(),9),
        endDate: new Date()
      })
    },
    {
      label: 'Last 1 year',
      range: () => ({
        startDate: subYears(new Date(),1),
        endDate: new Date()
      })
    },
  ])
];

function DatePicker({dates,changeDates, showSubTxt = false}) {
  

  const [state, setState] = useState(dates);

  const [anchorEl,
    setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseDate = () =>{
    setAnchorEl(null);
    changeDates(state);
  }

  const open = Boolean(anchorEl);
  const id = open
    ? 'simple-popover'
    : undefined;

  return (
    <div className={`date-picker-component ${showSubTxt ? 'spaced-apart' : ''}`}>
    {showSubTxt && (<SubText/>)}
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}>
        Choose Date
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
        <div className="evenly-spaced col-dir">
          <DateRangePicker
            onChange={item => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={isMobile
            ? 1
            : 1}
            ranges={state}
            direction="horizontal"
            staticRanges={staticRanges}
            />

          <Button aria-describedby={id} variant="contained" color="primary" onClick={chooseDate}>
            Confirm
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export default DatePicker
