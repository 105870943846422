import {makeStyles} from '@material-ui/core';
const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme
        .transitions
        .create([
          "width", "margin"
        ], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme
        .transitions
        .create([
          "width", "margin"
        ], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    topToolBar : {
        display : 'flex',
        justifyContent : 'space-between'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      zIndex : 2,
      color : theme.palette.common.white,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme
        .transitions
        .create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
      transition: theme
        .transitions
        .create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [
        theme
          .breakpoints
          .up("sm")
      ]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0, 1),
      color : theme.palette.common.white,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    whiteFont : {
      color : theme.palette.common.white,
    },
    selected : {
      backgroundColor : theme.palette.primary.main + ' !important',
      transition : 'all 0.3s'
      // padding : 10
    }
  }));