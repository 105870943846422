import React, {useEffect} from 'react'
import {
  makeStyles,
  TextField,
} from '@material-ui/core'

import { useQuery, QueryCache, ReactQueryCacheProvider } from 'react-query'

import Autocomplete from '@material-ui/lab/Autocomplete';
import { URLS } from '../../utils/routes';
import { ALL_OPTIONS, LONG_CACHE } from '../../utils/constants';
import { fetchApi } from '../../utils/hitApi';

const queryCache = new QueryCache();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

function MultipleSelect(props) {
  const classes = useStyles();

  const fetchAllAffiliates = async() =>{

    let result = await fetchApi({
      url : URLS.FETCH_ALL_AFFILIATES, 
      method : 'GET'
    });
    // return res;

    let {success} = result;
    if(success){
      result.affiliateUserList = [ALL_OPTIONS,...result.affiliateUserList];
      if(props.setLength){
        props.setLength(result.affiliateUserList.length - 1);
      }
    }
    
    return result;
  }

  const { isLoading, error, data } = useQuery('approvedAffiliateList', fetchAllAffiliates, {
     ...LONG_CACHE,
    retry: 1,
    refetchOnWindowFocus: false
  })

  const changeHandler = (e, value, reason) =>{
      props.changeHandler(value);
  }

  useEffect(() => {
    let isPageActive = true;

      if(isPageActive){
        const length = props.affilaiteLength;
        if(data && data.affiliateUserList && 
          length !== undefined && 
          length !== null && 
          length !== data.affiliateUserList.length){
            // debugger;
            // console.log('set length');
           props.setLength(data.affiliateUserList.length - 1);
        }
      }
    return () => {
      isPageActive = false;
    }
  }, [props?.affilaiteLength]);

  let selectedValue = props.value;
  // console.log(props.value);

  if(props.customSelect && data){
    // console.log(props.value);
    if(props.value && props.value.length > 0){
      // selectedValue = [data.affiliateUserList.filter(item => item.id === props.value[0].id)];
      selectedValue = props.value.map(item => data.affiliateUserList.find(e => e.id === item.id));
    }
  }

  return(
    <ReactQueryCacheProvider queryCache={queryCache}>
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-outlined"
        value={selectedValue}
        options={!isLoading ? data.affiliateUserList : []}
        loading={isLoading}
        getOptionLabel={(option) => option && option.name}
        filterSelectedOptions
        onChange={changeHandler}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Select affiliate to view their data"
            placeholder="+affiliate user"
          />
        )}
      />
    </div>
    </ReactQueryCacheProvider>
  )
}

export default MultipleSelect
