import React from "react";

function Empty() {
  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-palceholder"
        data-name="Layer 1"
        viewBox="0 0 997.861 450.808">
        <path fill="#f2f2f2" d="M871.992 181.558H902.151V285.956H871.992z"></path>
        <path
          fill="#f2f2f2"
          d="M922.068 266.317L848.715 179.052 701.475 180.398 612.156 267.396 613.961 268.556 613.316 268.556 613.316 449.513 921.871 449.513 921.871 268.556 922.068 266.317z"></path>
        <path
          fill="#e6e6e6"
          d="M848.792 179.238L757.154 286.674 757.154 449.513 921.871 449.513 921.871 266.236 848.792 179.238z"></path>
        <path fill="#3f3d56" d="M823.272 359.461H856.9110000000001V389.194H823.272z"></path>
        <path fill="#3f3d56" d="M823.272 307.996H856.9110000000001V337.258H823.272z"></path>
        <path fill="#fff" d="M823.272 359.461H856.9110000000001V389.194H823.272z"></path>
        <path fill="#fff" d="M823.272 307.996H856.9110000000001V337.258H823.272z"></path>
        <path fill="#fff" d="M673.777 351.571H707.416V381.30400000000003H673.777z"></path>
        <path fill="#fff" d="M673.777 300.106H707.416V329.368H673.777z"></path>
        <path fill="#f2f2f2" d="M633.992 181.558H664.151V285.956H633.992z"></path>
        <path
          fill="#f2f2f2"
          d="M684.068 266.317L610.715 179.052 463.475 180.398 374.156 267.396 375.961 268.556 375.316 268.556 375.316 449.513 683.871 449.513 683.871 268.556 684.068 266.317z"></path>
        <path
          fill="#e6e6e6"
          d="M610.792 179.238L519.154 286.674 519.154 449.513 683.871 449.513 683.871 266.236 610.792 179.238z"></path>
        <path fill="#3f3d56" d="M585.272 359.461H618.9110000000001V389.194H585.272z"></path>
        <path fill="#3f3d56" d="M585.272 307.996H618.9110000000001V337.258H585.272z"></path>
        <path fill="#fff" d="M585.272 359.461H618.9110000000001V389.194H585.272z"></path>
        <path fill="#fff" d="M585.272 307.996H618.9110000000001V337.258H585.272z"></path>
        <path fill="#fff" d="M435.777 351.571H469.416V381.30400000000003H435.777z"></path>
        <path fill="#fff" d="M435.777 300.106H469.416V329.368H435.777z"></path>
        <path fill="#f2f2f2" d="M380.154 91.46H420.454V230.961H380.154z"></path>
        <path
          fill="#f2f2f2"
          d="M447.068 204.718L349.051 88.112 152.302 89.91 32.951 206.161 35.362 207.711 34.501 207.711 34.501 449.513 446.804 449.513 446.804 207.711 447.068 204.718z"></path>
        <path
          fill="#e6e6e6"
          d="M349.153 88.36L226.702 231.921 226.702 449.513 446.804 449.513 446.804 204.611 349.153 88.36z"></path>
        <path fill="#3f3d56" d="M315.053 329.181H360.003V368.912H315.053z"></path>
        <path fill="#3f3d56" d="M315.053 260.412H360.003V299.513H315.053z"></path>
        <path fill="#fff" d="M315.053 329.181H360.003V368.912H315.053z"></path>
        <path fill="#fff" d="M315.053 260.412H360.003V299.513H315.053z"></path>
        <path fill="#fff" d="M115.29 318.639H160.24V358.37H115.29z"></path>
        <path fill="#fff" d="M115.29 249.869H160.24V288.97H115.29z"></path>
        <path fill="#3f3d56" d="M0 448.62H963.951V450.62H0z"></path>
        <ellipse cx="151.872" cy="352.472" fill="#3f3d56" rx="29.099" ry="59.374"></ellipse>
        <path
          fill="#a6d4fa"
          d="M255.629 674.254c-11.655-69.925-.118-139.598 0-140.293l2.267.384c-.117.692-11.588 69.999 0 139.532z"
          transform="translate(-101.07 -224.596)"></path>
        <path
          fill="#a6d4fa"
          d="M251.026 571.202H280.867V573.502H251.026z"
          transform="rotate(-28.142 -232.632 661.676)"></path>
        <path
          fill="#a6d4fa"
          d="M237.023 564.485H239.323V594.327H237.023z"
          transform="rotate(-61.842 .158 551.475)"></path>
        <ellipse cx="81.955" cy="260.903" fill="#a6d4fa" rx="56.915" ry="116.129"></ellipse>
        <path
          fill="#3f3d56"
          d="M189.364 675.404c-22.765-136.585-.23-272.673 0-274.032l2.268.384c-.23 1.356-22.699 137.077 0 273.27z"
          transform="translate(-101.07 -224.596)"></path>
        <path
          fill="#3f3d56"
          d="M179.276 475.125H237.644V477.425H179.276z"
          transform="rotate(-28.142 -290.118 565.599)"></path>
        <path
          fill="#3f3d56"
          d="M152.989 460.889H155.28900000000002V519.2570000000001H152.989z"
          transform="rotate(-61.843 -83.874 462.14)"></path>
        <ellipse cx="216.754" cy="191.008" fill="#a6d4fa" rx="77.883" ry="158.914"></ellipse>
        <path
          fill="#3f3d56"
          d="M326.916 675.404c-31.14-186.837-.314-372.992 0-374.85l2.268.384c-.315 1.854-31.074 187.643 0 374.089z"
          transform="translate(-101.07 -224.596)"></path>
        <path
          fill="#3f3d56"
          d="M312.694 401.831H392.565V404.13100000000003H312.694z"
          transform="rotate(-28.142 -145.948 492.305)"></path>
        <path
          fill="#3f3d56"
          d="M277.146 381.926H279.446V461.79699999999997H277.146z"
          transform="rotate(-61.843 40.286 393.929)"></path>
        <ellipse cx="871.029" cy="352.472" fill="#3f3d56" rx="29.099" ry="59.374"></ellipse>
        <path
          fill="#a6d4fa"
          d="M969.412 674.254c11.654-69.925.117-139.598-.001-140.293l-2.267.384c.117.692 11.588 69.999 0 139.532z"
          transform="translate(-101.07 -224.596)"></path>
        <path
          fill="#a6d4fa"
          d="M957.944 557.431H960.2439999999999V587.272H957.944z"
          transform="rotate(-61.858 721.14 544.394)"></path>
        <path
          fill="#a6d4fa"
          d="M971.946 578.256H1001.788V580.5559999999999H971.946z"
          transform="rotate(-28.158 488.56 668.608)"></path>
        <ellipse cx="940.946" cy="260.903" fill="#a6d4fa" rx="56.915" ry="116.129"></ellipse>
        <path
          fill="#3f3d56"
          d="M1035.676 675.404c22.765-136.585.23-272.673 0-274.032l-2.267.384c.23 1.356 22.698 137.077 0 273.27z"
          transform="translate(-101.07 -224.596)"></path>
        <path
          fill="#3f3d56"
          d="M1015.43 447.091H1017.7299999999999V505.459H1015.43z"
          transform="rotate(-61.858 778.626 448.317)"></path>
        <path
          fill="#3f3d56"
          d="M1041.717 488.923H1100.085V491.223H1041.717z"
          transform="rotate(-28.157 572.585 579.279)"></path>
        <ellipse cx="806.148" cy="191.008" fill="#a6d4fa" rx="77.883" ry="158.914"></ellipse>
        <path
          fill="#3f3d56"
          d="M898.124 675.404c31.14-186.837.315-372.992 0-374.85l-2.267.384c.314 1.854 31.073 187.643 0 374.089z"
          transform="translate(-101.07 -224.596)"></path>
        <path
          fill="#3f3d56"
          d="M871.261 363.045H873.5609999999999V442.916H871.261z"
          transform="rotate(-61.858 634.456 375.023)"></path>
        <path
          fill="#3f3d56"
          d="M906.809 420.712H986.68V423.012H906.809z"
          transform="rotate(-28.157 448.417 511.073)"></path>
        <path
          fill="#3f3d56"
          d="M690.674 326.062l9.205-7.363c-7.151-.789-10.09 3.111-11.292 6.198-5.587-2.32-11.67.72-11.67.72l18.42 6.688a13.938 13.938 0 00-4.663-6.243zM465.674 261.062l9.205-7.363c-7.151-.789-10.09 3.111-11.292 6.198-5.587-2.32-11.67.72-11.67.72l18.42 6.688a13.938 13.938 0 00-4.663-6.243zM832.674 232.062l9.205-7.363c-7.151-.789-10.09 3.111-11.292 6.198-5.587-2.32-11.67.72-11.67.72l18.42 6.688a13.938 13.938 0 00-4.663-6.243zM851.26 661.648a13.918 13.918 0 00-6.97 1.87 14.982 14.982 0 00-25.03 11.13h45.95a13.99 13.99 0 00-13.95-13zM384.26 661.648a13.918 13.918 0 00-6.97 1.87 14.982 14.982 0 00-25.03 11.13h45.95a13.99 13.99 0 00-13.95-13zM623.26 661.648a13.918 13.918 0 00-6.97 1.87 14.982 14.982 0 00-25.03 11.13h45.95a13.99 13.99 0 00-13.95-13z"
          transform="translate(-101.07 -224.596)"></path>
        <path
          fill="#3f3d56"
          d="M471.759 404.228L339.191 404.228 339.191 408.504 359.866 408.504 359.866 449.13 364.142 449.13 364.142 408.504 444.669 408.504 444.669 449.13 448.946 449.13 448.946 408.504 471.759 408.504 471.759 404.228z"></path>
        <path fill="#3f3d56" d="M339.452 391.434H472.02V395.71000000000004H339.452z"></path>
        <path fill="#3f3d56" d="M339.452 380.743H472.02V385.019H339.452z"></path>
        <path fill="#3f3d56" d="M339.452 370.052H472.02V374.32800000000003H339.452z"></path>
        <path
          fill="#3f3d56"
          d="M678.759 404.228L546.191 404.228 546.191 408.504 566.866 408.504 566.866 449.13 571.142 449.13 571.142 408.504 651.669 408.504 651.669 449.13 655.946 449.13 655.946 408.504 678.759 408.504 678.759 404.228z"></path>
        <path fill="#3f3d56" d="M546.452 391.434H679.02V395.71000000000004H546.452z"></path>
        <path fill="#3f3d56" d="M546.452 380.743H679.02V385.019H546.452z"></path>
        <path fill="#3f3d56" d="M546.452 370.052H679.02V374.32800000000003H546.452z"></path>
      </svg>
      <h3 className="empty-txt">Oops! No data found.</h3>
    </React.Fragment>
  );
}

export default Empty;
