import React from 'react'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {AuthContext} from '../../context/Auth';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Logout({isIcon}) {
  const {setUserData} = React.useContext(AuthContext);

  const [open,
    setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    setUserData(null);
    setOpen(false);
  }

  return (
    <React.Fragment>
      {isIcon ? <ExitToAppIcon onClick={handleClickOpen}/> : (<div></div>)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to logout?"}</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={logout} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default Logout
