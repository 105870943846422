import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
    //   minWidth: 120,
    width : '100%',
    marginTop : '0px !important'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

function CustomSelect({handleChange, value, label, options, defaultValue}) {

    const classes = useStyles();

    const changeHandler = (e) =>{
      let value = e.target.value;
      handleChange(value);
    }

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
            <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={value}
            onChange={changeHandler}
            defaultValue={defaultValue}
            label={label}
            >
            {options.map(item =>{
                let {id, title, itemValue} = item;
                return (<MenuItem value={itemValue} key={id}>{title}</MenuItem>)
            })}
            </Select>
      </FormControl>
    )
}

export default CustomSelect
