import {
    makeStyles,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    DialogActions
  } from '@material-ui/core'
  import React from 'react'
  
  import {useMutation} from 'react-query';
    import { URLS } from '../../utils/routes';
    import { fetchApi } from '../../utils/hitApi';
    import { Skeleton } from '@material-ui/lab';
    import CouponTable from './CouponTable';

    import IconButton from '@material-ui/core/IconButton';
  import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';

  const useStyles = makeStyles(theme => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    modal : {
      minWidth : '70vw'
    }
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props}/>;
  });

  function CouponCard(props) {

    const classes = useStyles();

    const {couponCodeId, couponCode, applicationType, name} = props;
    // console.log({applicationType});

    const [open, setOpen] = React.useState(false);
    // const [data, setData] = React.useState(false);

    const fetchCouponList = async({couponCodeId}) =>{
        const url = URLS.FETCH_SINGLE_COUPON_DATA;
        const res = await fetchApi({
          url ,
          // Adding method type
          method: "POST",
          // Adding body or contents to send
          body : {
            couponId : couponCodeId
          },
        });
        return res;
    }

    const [mutate, { isLoading, isError, isSuccess, status, data, error, reset }] = useMutation(fetchCouponList);

    const fetchList = async() =>{
        await mutate({couponCodeId});
    }

    const handleClickOpen = () => {
        setOpen(true);
        fetchList();
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      if(applicationType === 'ORDER'){
          return <span> {couponCode} </span>
      }else{
        return (
          <React.Fragment>
            <Button variant={"outlined"} color="primary" onClick={handleClickOpen}>
              {couponCode}
            </Button>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              // fullWidth
              maxWidth={isMobile ? 'xs' : 'lg'}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description">
              <DialogTitle id="alert-dialog-slide-title">Details of {couponCode} assigned to {name}
                 <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
               <CloseIcon />
            </IconButton>
              </DialogTitle>
              <DialogContent>
              {isLoading ? (
                  <React.Fragment>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                  </React.Fragment>
              ) : (isSuccess && data.success) ? (
                    <React.Fragment>
                    <CouponTable data={data} applicationType={applicationType}/>
                    </React.Fragment>
                ) : null
              }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )
     }
  }

  export default CouponCard
  