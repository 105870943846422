import React from 'react'
import { SvgIcon } from '@material-ui/core'

function LoginIcon(props) {
  return (
      <SvgIcon {...props}>
      <path
        d="M474.668 341.332h-5.336v-32C469.332 279.937 445.398 256 416 256s-53.332 23.937-53.332 53.332v32h-5.336c-20.586 0-37.332 16.746-37.332 37.336v74.664c0 20.59 16.746 37.336 37.332 37.336h117.336c20.586 0 37.332-16.746 37.332-37.336v-74.664c0-20.59-16.746-37.336-37.332-37.336zm-80-32c0-11.754 9.578-21.332 21.332-21.332s21.332 9.578 21.332 21.332v32h-42.664zm0 0M288 378.668c0-28.8 17.645-53.527 42.668-64v-5.336c0-12.969 3.133-25.129 8.34-36.16-16.13-10.817-35.5-17.172-56.34-17.172H101.332C45.461 256 0 301.46 0 357.332V432c0 8.832 7.168 16 16 16h272zm0 0M298.668 106.668c0 58.91-47.758 106.664-106.668 106.664S85.332 165.578 85.332 106.668C85.332 47.758 133.09 0 192 0s106.668 47.758 106.668 106.668zm0 0"></path>
    </SvgIcon>
  )
}

export default LoginIcon
