import React, {useContext} from 'react'
import {URLS} from '../utils/routes';
import {usePaginatedQuery} from 'react-query';
import {LONG_CACHE, PAGE_SIZE,SERVER_DATE_FORMAT, WEBSITE_DATE_FORMAT,WEBSITE_TIME_DATE_FORMAT} from '../utils/constants';
import { useBrand } from '../hooks/useBrand';
import { AuthContext } from '../context/Auth';
import TableComponent from '../components/Tables/TableComponent';
import { SerialNumberHeader } from '../components/Tables/SerialNumberHeader';
import { Button } from '@material-ui/core';
import dayjs from 'dayjs';
import {subMonths} from 'date-fns';
import DatePicker from '../components/DatePicker';
import LinkTag from '../components/LinkComponents/LinkTag';
import LinkIconButton from '../components/LinkComponents/LinkIconButton';
import Modal from '../components/Modal/Modal';
import swal from 'sweetalert';
import { isNotNullOrEmpty } from '../utils/validations';
import Toastify from '../components/Toast/Toastify';
import { toast } from 'react-toastify';
import { displayToast } from '../utils/displayToast';
import { fetchApi } from '../utils/hitApi';
import { useRouterQuery } from '../hooks/useRouterQuery';

let pageSize = PAGE_SIZE;

function PendingAffiliates() {

  const [page, setPage] = React.useState(1);

  const query = useRouterQuery();

  const status = query.get("status");
  
  // console.log({status});
  // const [showModal, setShowModal] = React.useState(false);

  const [dates, setDates] = React.useState([
    {
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [searchedTerm, setSearchdTerm] = React.useState("");

  const [removeList, setRemoveList] = React.useState([]);
    
    const {userData} = useContext(AuthContext);

    const [brandName] = useBrand();

    const fetchAffiliates = async(key, pageNo = 1, pageSize, startDate, endDate) => {
      if(!!startDate && !!pageNo && !!pageSize && !!endDate){
        let sDate = dayjs(startDate).format(SERVER_DATE_FORMAT);
        let eDate = dayjs(endDate).format(SERVER_DATE_FORMAT);

        const res = await fetchApi({
          url : URLS.FETCH_AFFILIATE_USERS,

          // Adding method type
          method: "POST",

          // Adding body or contents to send
          body: {
            "pageNo": pageNo, 
              "pageSize": pageSize, 
              "searchTerm": searchedTerm, 
              status : "PENDING", 
              startDate : sDate, 
              endDate : eDate
            },
        });
      // const result = await res.json();
      return res;

        // console.log({sDate, eDate, pageNo, pageSize});
        // const res = await fetch(URLS.FETCH_AFFILIATE_USERS, {
  
        //   // Adding method type
        //   method: "POST",
  
        //   // Adding body or contents to send
        //   body: JSON.stringify({
        //     "pageNo": pageNo, 
        //     "pageSize": pageSize, 
        //     "searchTerm": searchedTerm, 
        //     status : "PENDING", 
        //     startDate : sDate, 
        //     endDate : eDate
        // }),
  
        //   // Adding headers to the request
        //   headers: {
        //     "Content-type": "application/json; charset=UTF-8"
        //   }
        // });
        // const result = await res.json();
        // return result;
     }else{
       return {};
     }
    }
  
    const increasePageCount = React.useCallback(
      (pageNo,newPageSize) => {
        if(pageNo > 1){
          setPage(pageNo);
          pageSize = newPageSize;
        }
      },
      [],
    );
  
    const changeSearchedTerm = React.useCallback(
      (value) => {
        setSearchdTerm(value);
        setPage(1);
      },
      [],
    );

    const {
      isLoading,
      isError,
      error,
      resolvedData,
      latestData,
      isFetching
    } = usePaginatedQuery([
      'products', page, pageSize, dates[0].startDate, dates[0].endDate
    ], fetchAffiliates, {
      ...LONG_CACHE,
      retry: 1,
      refetchOnWindowFocus: false
    });

    const changeDates = React.useCallback(
      (data) => {
        // console.log(data);
        setDates(data)
      },
      [],
    )

    const changeStatus = (id, caseType, name) =>{
      let swalTxt = "";
      if(caseType === 'APPROVE'){
        swalTxt = `Enter % commission you want to give to ${name}`;
      }else{
        swalTxt = `Please enter reason to reject ${name}`;
      }

      swal({
        text: swalTxt,
        content: "input",
        button: {
          text: "Submit",
          closeModal: false,
        },
      }).then(inputValue => {
        let isValidValue = false, commission, rejectReason = null, errorMsg = "";
        if(caseType === 'APPROVE'){
          commission = parseFloat(inputValue);
          if(isNotNullOrEmpty(commission) && !isNaN(commission) && /[0-9]*/g.test(commission) && commission <= 100){
            isValidValue = true;
          }else{
            if(isNaN(commission)){
              errorMsg = 'Only numbers allowed';
            }else if(!commission <= 100){
              errorMsg = 'Commisison percentage should be less than 100';
            }else{
              errorMsg = 'Please enter valid commission percentage';
            }
          }
        }else{
          rejectReason = inputValue;
          if(isNotNullOrEmpty(rejectReason)){
            isValidValue = true;
          }else{
            errorMsg = 'Please enter valid rejection reason';
          }
        }
        if(isValidValue === false){
          displayToast({toastMsg : errorMsg, toastType : 'error'});
          swal.stopLoading();
        }else{
          let body = {
            affiliateUserId : id,
            status : caseType,
            commission,
            rejectReason
          }
          return updateUserStatus(body);
        }
      }).then(res =>{
        swal.stopLoading();
        let {success, msg} = res;
        if(success){
          setRemoveList(prevList => ([...prevList, id]));
          swal.close();
        }
        // displayToast({toastMsg : `${name} is approved as a affiliate user successfully`, toastType : 'success'});
        displayToast({toastMsg : msg, toastType : success ? 'success' : 'error'});
      });
    }

    const changeStatus1 = (id, caseType, name) =>{
      if(caseType === 'APPROVE'){
        swal({
          text: `Enter % commission you want to give to ${name}`,
          content: "input",
          button: {
            text: "Submit",
            closeModal: false,
          },
        }).then(commission => {
          if(isNotNullOrEmpty(commission) && /[0-9]*/g.test(parseFloat(commission)) && parseFloat(commission) <= 100){
            let body = {
              affiliateUserId : id,
              status : caseType,
              commission,
              rejectReason : ""
            };
            return updateUserStatus(body);
          }else{
            displayToast({toastMsg : 'Please enter valid commission percentage', toastType : 'error'});
              swal.stopLoading();
          }
        }).then(res =>{
          swal.stopLoading();
          let {success, msg} = res;
          if(success){
            setRemoveList(prevList => ([...prevList, id]));
            swal.close();
          }
          // displayToast({toastMsg : `${name} is approved as a affiliate user successfully`, toastType : 'success'});
          displayToast({toastMsg : msg, toastType : success ? 'success' : 'error'});
        });
      }else{
        swal({
          text: `Please enter reason to reject ${name}`,
          content: "input",
          button: {
            text: "Submit",
            closeModal: false,
          },
        }).then(reason => {
          if(isNotNullOrEmpty(reason)){
            setRemoveList(prevList => ([...prevList, id]));
            swal.stopLoading();
            swal.close();
            displayToast({toastMsg : `${name} is rejected successfully.`, toastType : 'success'});
          }else{
            displayToast({toastMsg : 'Please enter valid rejection reason', toastType : 'error'});
              swal.stopLoading();
          }
        });
      }
    }

    const updateUserStatus = async(body) =>{
        let res = await fetchApi({
          body, 
          url : URLS.CHANGE_AFFILIATE_STATUS, 
          // headers: {
          //   "Content-type": "application/json; charset=UTF-8"
          // },
          method : 'POST'
        });
        return res;
    }

    const columns = React.useMemo(() => [
      // SerialNumberHeader,
      {
        Header: 'Name',
        accessor: 'name'
      }, 
      {
        Header: 'Mobile Number',
        accessor: 'mobileNo'
      },
      {
        Header: 'E-mail',
        accessor: 'emailId'
      },
      {
        Header: 'Details',
        accessor: 'details'
      },
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Monthly visitors',
        accessor: 'noOfVisitors'
      },
      {
        Header: () => 'Action',
        id: 'action', // It needs an ID
        Cell: ({row}) => { 
          let {original} = row;
          let {id, name} = original;

          return (
            <div className="evenly-spaced">
            <Button size="small" variant="contained" color="primary" onClick={()=>{changeStatus(id, 'APPROVE', name)}}>
              Approve
            </Button>
            <Button size="small" variant="outlined" color="secondary" onClick={()=>{changeStatus(id, 'REJECT', name)}}>
              Reject
            </Button>
          </div>
          )
        }
      }
    ], []);

    if(isError){
      return <div>Error occurred</div>
    }

  return (
    <React.Fragment>
      <DatePicker dates={dates} changeDates={changeDates}/>
      {/* <div>{getDates(dates)}</div> */}
    <TableComponent columns={columns} 
        data={isError ? [] : resolvedData}
        // data = {[]}
        fetchData={increasePageCount}
        loading={isLoading}
        // pageCount={1000}
        brandName={brandName}
        userData={userData}
        changeKey = {null}
        initialPage = {1}
        currentPageNumber = {page}
        manualSearch = {true}
        manualPagination = {true}
        // changeSearchedTerm = {changeSearchedTerm}
        modifyTableData = {modifyTableData}
        subTitle = 'Pending Affiliates'
        tableTitle = {getDates(dates)}
        listName = "affiliateUserList"
        removeList={removeList}
        />
        <Toastify/>
        </React.Fragment>
  )
}

function getDates(dates){
  if(!dates || dates.length === 0){
    return '-';
  }
  let startDate = dayjs(dates[0].startDate).format(WEBSITE_DATE_FORMAT);
  let endDate = dayjs(dates[0].endDate).format(WEBSITE_DATE_FORMAT);
  return `${startDate} to ${endDate}`;
}

function modifyTableData(list){
  let tableData = list.map((item) =>{
    let {registrationDate, websiteUrls = [], mobileAppUrls = [], socialMediaUrls = []} = item;
    let details = [...socialMediaUrls, ...websiteUrls, ...mobileAppUrls].map(link => {
        // return <span key={link.url}>{link.platform ? <b>{link.platform} : </b> : ""} {link.url}<br/></span>
      let itemLink = link.url;

        return (
          <LinkTag itemLink={itemLink} key={itemLink}>
            <LinkIconButton size="small"/> {link.platform ? <b>{link.platform}</b> : itemLink}
            <br/>
          </LinkTag>
        )
    });

    let date = dayjs(registrationDate).format(WEBSITE_TIME_DATE_FORMAT);

    return({
      ...item,
      details : details,
      date
    });

  });
  return tableData;
}

export default PendingAffiliates
