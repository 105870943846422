import React from 'react'
import {Link} from 'react-router-dom';
function NotFound() {
    return (
        <div>
            <h3>Oops! 404</h3>
            <Link to="/home">Return to dashboard</Link>
        </div>
    )
}

export default NotFound
