import React, {useContext} from 'react'
import {URLS} from '../../utils/routes';
import {usePaginatedQuery} from 'react-query';
import {LONG_CACHE, PAGE_SIZE} from '../../utils/constants';
import {CopyLinkTableColumn} from './CopyLinkTableColumn';
import {OpenLinkTableColumn} from './OpenLinkTableColumn';

import { useBrand } from '../../hooks/useBrand';
// import { useTable, usePagination} from 'react-table'
import { AuthContext } from '../../context/Auth';
// import {TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import TablePaginationActions from '../Tables/TablePaginationActions';
// import TableToolbar from '../Tables/TableToolbar';
import { SerialNumberHeader } from '../Tables/SerialNumberHeader';
// import LinearProgress from '@material-ui/core/LinearProgress';

import TableComponent from '../../components/Tables/TableComponent';
import { BASE_WEBSITE_PATH } from '../../utils/baseRoutes';
import { getLocalStorageKey } from '../../hooks/useLocalStorageHook';
import { fetchApi } from '../../utils/hitApi';
import { useData } from '../../hooks/useData';

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
//   container: {
//     maxHeight: '70vh',
//   },
// });
const listName = "productList";
const url = URLS.FETCH_PRODUCTS;
let pageSize = PAGE_SIZE;
function Products({category}) {
  const pageData = React.useRef([]);
  const [page, setPage] = React.useState(1)
  const [searchedTerm, setSearchdTerm] = React.useState("");
    
    const {userData} = useContext(AuthContext);

    const [brandName] = useBrand();

    React.useEffect(() => {
      let isActive = true;

      if(isActive){
        setPage(1);
      }

      return () => {
        isActive = false;
      }
    }, [category]);

  const fetchProducts = async(key, pageNo = 1, pageSize, searchedTerm, category) => {
    // console.log('[fetchProducts ]: ', {pageNo, category, searchedTerm});
    // POST request using fetch()
    if(!!category && !!pageNo && !!pageSize){

        const res = await fetchApi({
          url,

          // Adding method type
          method: "POST",

          // Adding body or contents to send
          body: {
            "categoryId": category,
            "pageNo": pageNo, 
            "pageSize": pageSize, 
            "searchTerm": searchedTerm
        },

          // Adding headers to the request
          // headers: {
          //   "Content-type": "application/json; charset=UTF-8"
          // }
        });
        // const result = await res.json();
        return res;

      // const res = await fetch(url, {

      //   // Adding method type
      //   method: "POST",

      //   // Adding body or contents to send
      //   body: JSON.stringify({"categoryId": category, "pageNo": pageNo, "pageSize": pageSize, "searchTerm": searchedTerm}),

      //   // Adding headers to the request
      //   headers: {
      //     "Content-type": "application/json; charset=UTF-8"
      //   }
      // });
      // const result = await res.json();
      // return result;
   }else{
     return {};
   }
  }

  const increasePageCount = React.useCallback(
    (pageNo,newPageSize) => {
      setPage(pageNo);
      // if(pageNo > 1){
      //   setPage(pageNo);
      //   pageSize = newPageSize;
      // }else{
      //   console.log('set page no 1');
      // }
    },
    [],
  );

  const changeSearchedTerm = React.useCallback(
    (value) => {
      setSearchdTerm(value);
      setPage(1);
    },
    [],
  );

  const {
    isLoading,
    isError,
    error,
    resolvedData,
    latestData,
    isFetching
  } = usePaginatedQuery([
    'products', page, pageSize, searchedTerm, category
  ], fetchProducts, {
    ...LONG_CACHE,
    retry: 1,
    refetchOnWindowFocus: false
  });

  const columns = React.useMemo(() => [
    SerialNumberHeader,
    ImageComponent,
    {
      Header: 'Product Name',
      accessor: 'name'
    }, 
    OpenLinkTableColumn,
    CopyLinkTableColumn
  ], []);

  if(resolvedData && Object.keys(resolvedData).length > 0){
      pageData.current[page] = resolvedData[listName];
  }

  // const [tablePageData] = useData(resolvedData, [], listName, page);

  // console.log({tablePageData});
  // console.log(pageData.current, {page, resolvedData, latestData});

  return (
    <TableComponent columns={columns} 
        // data={tablePageData}
        data={resolvedData ? {[listName] : pageData.current[page], totalRecords : resolvedData.totalRecords} : []}
        // data = {[]}
        fetchData={increasePageCount}
        loading={isLoading}
        // pageCount={1000}
        brandName={brandName}
        userData={userData}
        changeKey = {category}
        initialPage = {1}
        currentPageNumber = {page}
        manualSearch = {true}
        manualPagination = {true}
        changeSearchedTerm = {changeSearchedTerm}
        modifyTableData = {modifyTableData}
        subTitle="Click on icon to copy link"
        tableTitle = 'Get shareable product link'
        listName = {listName}
        isFetching = {isFetching}
        currentPageSize = {pageSize}
        />
  )
}

function modifyTableData(list, userData, brandName, currentPageNumber){
  if(!brandName){
     brandName = getLocalStorageKey('brand');
  }
  const frontEndUrl =  BASE_WEBSITE_PATH[brandName].FRONTEND;

  let {userCode} = userData;
  // console.log('currentPageNumber : ', currentPageNumber);

  userCode = userCode.replace(" ", "-");
  // console.log({list});

  let tableData = list.map((item, index) =>{
      let {name, title, productId, productInfoId, versionKey} = item;
      let itemLink = `${frontEndUrl}${title}/p?id=${productId}&infoId=${productInfoId}&tag=${userCode}`,
      productImage = `${frontEndUrl}fetchImage?imageType=thumbnailImageByProductInfoId&id=${productInfoId}&v=${versionKey}&dim=60x60`;
      
      return ({
          // srNo : index + 1,
          name,
          itemLink,
          productImage,
          id : productInfoId
      });
  });
  return tableData;
}

// function TableComponent1({data : tableData = {}, columns, fetchData, loading, 
//   pageCount: controlledPageCount,userData, brandName, category, initialPage, 
//   currentPageNumber, changeSearchedTerm, modifyTableData}){
//   let {productList = [], totalRecords = 0} = tableData;
//   controlledPageCount = parseInt(totalRecords);
//   const classes = useStyles();

//   let data = modifyTableData(productList, userData, brandName, currentPageNumber);
  
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     prepareRow,
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     // Get the state from the instance
//     state: { pageIndex, pageSize },
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageIndex: 1 }, // Pass our hoisted table state
//       manualPagination: true, // Tell the usePagination
//       // hook that we'll handle our own data fetching
//       // This means we'll also have to provide our own
//       // pageCount.
//       pageCount: controlledPageCount,
//     },
//     usePagination
//   )

//    // Listen for changes in pagination and use the state to fetch our new data
//    React.useEffect(() => { 
//    console.log('category changed');
//   }, [category]);
  
//   // Listen for changes in pagination and use the state to fetch our new data
//   React.useEffect(() => { 
//     if(pageIndex >= 1){
//       //console.log('fetching data with ', pageIndex);
//       fetchData(pageIndex, pageSize);
//     }
//   }, [fetchData, pageIndex, pageSize, category])

//   React.useEffect(() => {
//     // if(pageIndex >= 1){
//     //   console.log('set page no to 1');
//     //   // gotoPage(0);
//     // }
//     if(pageIndex !== currentPageNumber){
//       console.log('not in sync, change', {pageIndex, currentPageNumber});

//     }
//   }, [currentPageNumber, pageIndex]);

//   const handleChangePage = (event, newPage) => {
//     //console.log('[handleChangePage] newPage : ', newPage);
//     // fetchData(newPage + 1, pageSize);
//     gotoPage(newPage + 1)
//   };

//   const handleChangeRowsPerPage = (event) => {
//     //console.log('in handleChangeRowsPerPage');
//     // fetchData(parseInt(event.target.value, 10),1);
//     setPageSize(Number(event.target.value))
//   };

//   const handleSearchFilter = React.useCallback(
//     (value) => {
//       changeSearchedTerm(value);
//       gotoPage(1)
//     },
//     [],
//   )

//   // const handleSearchFilter = (value) =>{
//   //   changeSearchedTerm(value);
//   //   gotoPage(1)
//   // }

//   // //console.log('pageIndex : ', pageIndex);
//   // Render the UI for your table
//   return (
//     <React.Fragment>
//       {loading && <LinearProgress />}
    
//       <Paper className={classes.root}>
//       <TableContainer className={classes.container}>
//       <TableToolbar tableTitle={'Get shareable product link'} handleSearchFilter={handleSearchFilter}/>
//       <Table aria-label="simple table" {...getTableProps()} stickyHeader={true}>
//       <TableHead>
//           {headerGroups.map(headerGroup => (
//             <TableRow {...headerGroup.getHeaderGroupProps()}>
//               {headerGroup.headers.map(column => (
//                 <TableCell {...column.getHeaderProps()}>
//                   {column.render('Header')}
//                   <span>
//                     {column.isSorted
//                       ? column.isSortedDesc
//                         ? ' 🔽'
//                         : ' 🔼'
//                       : ''}
//                   </span>
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableHead>
//         <TableBody {...getTableBodyProps()}>
//           {page.map((row, i) => {
//             prepareRow(row)
            
//             return (
//               <TableRow {...row.getRowProps()}>
//                 {row.cells.map(cell => {
//                   return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
//                 })}
//               </TableRow>
//             )
//           })}
//           {/* <TableRow>
//             {loading ? (
//               // Use our custom loading state to show a loading indicator
//               <TableCell colSpan="10000">Loading...</TableCell>
//             ) : (
//               <TableCell colSpan="10000">
//                 Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
//                 results
//               </TableCell>
//             )}
//           </TableRow> */}
//         </TableBody>
//       </Table>
//     </TableContainer>
//     <TableFooter>
//           <TableRow>
//             <TablePagination
//               rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
//               colSpan={3}
//               count={controlledPageCount}
//               rowsPerPage={10}
//               page={pageIndex - 1}
//               SelectProps={{
//                 inputProps: { 'aria-label': 'rows per page' },
//                 native: true,
//               }}
//               onChangePage={handleChangePage}
//               onChangeRowsPerPage={handleChangeRowsPerPage}
//               ActionsComponent={TablePaginationActions}
//             />
//           </TableRow>
//         </TableFooter>
//       {/* 
//         Pagination can be built however you'd like. 
//         This is just a very basic UI implementation:
//       */}
//       {/* <div className="pagination">
//         <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
//           {'<<'}
//         </button>{' '}
//         <button onClick={() => previousPage()} disabled={!canPreviousPage}>
//           {'<'}
//         </button>{' '}
//         <button onClick={() => nextPage()} disabled={!canNextPage}>
//           {'>'}
//         </button>{' '}
//         <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
//           {'>>'}
//         </button>{' '}
//         <span>
//           Page{' '}
//           <strong>
//             {pageIndex + 1} of {pageOptions.length}
//           </strong>{' '}
//         </span>
//         <span>
//           | Go to page:{' '}
//           <input
//             type="number"
//             defaultValue={pageIndex + 1}
//             onChange={e => {
//               const page = e.target.value ? Number(e.target.value) - 1 : 0
//               gotoPage(page)
//             }}
//             style={{ width: '100px' }}
//           />
//         </span>{' '}
//         <select
//           value={pageSize}
//           onChange={e => {
//             setPageSize(Number(e.target.value))
//           }}
//         >
//           {[10, 20, 30, 40, 50].map(pageSize => (
//             <option key={pageSize} value={pageSize}>
//               Show {pageSize}
//             </option>
//           ))}
//         </select>
//       </div> */}
//       </Paper>
//       </React.Fragment>
//   )
// }

const ImageComponent = {
    // Make an expander cell
    Header: () => 'Product Image',
    id: 'productImage', // It needs an ID
    Cell: ({row}) => {
      let {original} = row;
      let {productImage} = original;    
      return (<img src={productImage} alt="product"/>)
    }
}

export default Products
