import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

function SearchInput(props) {
  const classes = useStyles();
  return (
    <div className={classes.margin}>
    <TextField
            id="input-with-icon-grid"
            label="Search"
            variant="outlined"
            value={props.value}
            InputProps={{
            startAdornment: <SearchIcon/>,
          }}
            onChange={props.onChange}/>
      {/* <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <SearchIcon/>
        </Grid>
        <Grid item>
          <TextField
            id="input-with-icon-grid"
            label="Search"
            variant="outlined"
            value={props.value}
            InputProps={{
            startAdornment: <SearchIcon/>,
          }}
            onChange={props.onChange}/>
        </Grid>
      </Grid> */}
    </div>
  )
}

export default SearchInput
