import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Button} from '@material-ui/core';
import {getWebsiteDate} from '../../utils/functions';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeletePayment from './DeletePayment';
import { AuthContext } from '../../context/Auth';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableAaction: {
    display: 'flex',
    justifyContent: 'space-evenly'
  }
});

function PaymentTable({data}) {
  const classes = useStyles();
  const {isAdmin} = React.useContext(AuthContext);

  const [list, setList] = React.useState(data.paymentList)

  const removeList = (id) =>{
    let newList = data.paymentList.filter(item => item.paymentId !== id);
    setList(newList);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell align="right">Sr No.</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Payment Mode</TableCell>
            <TableCell align="right">Payment Date</TableCell>
            {isAdmin && (
            <TableCell align="center">Action</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
              let {paymentId, paymentMode, amount, datePayment} = item;
              return (
                <TableRow key={paymentId}>
                  <TableCell align="right">{index + 1}</TableCell>
                  <TableCell align="right">&#8377; {parseFloat(amount)}</TableCell>
                  <TableCell align="right">{paymentMode}</TableCell>
                  <TableCell align="right">{getWebsiteDate(datePayment)}</TableCell>
                  {isAdmin && (
                  <TableCell align="right" className={classes.tableAaction}>
                    <Link to={`add-edit-payment?id=${paymentId}&status=UPDATE`}>
                    <Button size="small" variant="outlined" color="primary">
                        <EditIcon/>
                    </Button>
                    </Link>
                    <DeletePayment paymentId={paymentId} 
                    // removeList={removeList}
                    />
                  </TableCell> )}
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PaymentTable
