export const SerialNumberHeader = {
    Header: "Sr No",
    id: "row",
    maxWidth: 50,
    filterable: false,
    Cell: (row) => {
      let {pageIndex} = row.state;
      let {index} = row.cell.row;
      // console.log({pageIndex, index});
      let srNo = ``;

      if(pageIndex === 1){
        srNo = index + 1;
      }else{
        if(index === 9){
          srNo = `${pageIndex}0`;
        }else{
          srNo = `${pageIndex - 1}${index + 1}`;
        }
      }
        return <div>{srNo}</div>;
    }
};