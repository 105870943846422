import { makeValidLink } from "../../utils/functions";
import LinkIconButton from "./LinkIconButton";
import LinkTag from "./LinkTag";

export const OpenLinkTableColumn = {
  Header: () => 'Open Link',
  id: 'openLink', // It needs an ID
  Cell: ({row}) => {
    let {original} = row;
    let {itemLink} = original;

    let tempLink = makeValidLink(itemLink);
    // console.log({itemLink});
    return (
      <LinkTag itemLink={tempLink}>
          <LinkIconButton/>
      </LinkTag>
    )
  }
}