import React from 'react'
import {Grid, LinearProgress} from '@material-ui/core';
import Cards from '../components/Cards';
import {DASHBOARD_CARDS} from '../constants/DashboardCards';
import DatePicker from '../components/DatePicker';
import {ALL_OPTIONS, INITIAL_DATE_SETUP, LONG_CACHE, PAGE_SIZE} from '../utils/constants';
import {getServerDate, getWebsiteDate} from '../utils/functions';
import { AuthContext } from '../context/Auth';
import MultipleSelect from '../components/MultiSelect/MultipleSelect';
import { useQuery } from 'react-query';
import { fetchApi } from '../utils/hitApi';
import { URLS } from '../utils/routes';
import { Skeleton } from '@material-ui/lab';
import { isMobile } from 'react-device-detect';

// let MultipleSelect = React.lazy(()=>import('../components/MultiSelect/MultipleSelect'));

function Home() {
  const {isAdmin, userData, setUserData} = React.useContext(AuthContext);
  const [dates, setDates] = React.useState(INITIAL_DATE_SETUP);
  
  const [affiliateUsers, setAffiliateUsers] = React.useState([ALL_OPTIONS]);

  const [affilaiteLength, setAffialiteLength] = React.useState(0);

  // console.log({affiliateUsers, affilaiteLength});

  const [affiliateUsersList, setAffiliateUsersList] = React.useState(()=> isAdmin ? [] : [userData.id]);

  const [allAffiliate, setAllAffiliate] = React.useState(isAdmin);

  const changeDates = React.useCallback((data) => {
    setDates(data)
  }, []);

  const changeAffiliates = React.useCallback(
    (data) => {
      setAffiliateUsers(data);
      let isAllSelected = false;
      let list = data.map(item => {
        if(item.id === -1){
          isAllSelected = true;
        }
        return item.id
      });
      setAffiliateUsersList(isAllSelected ? [] : list);
      setAllAffiliate(isAllSelected);
    },
    [],
  )

  const fetchDashboardData = async(key, startDate, endDate, affiliateUsersList) =>{

    if(!!startDate && !!endDate){
      // let sDate = getServerDate(startDate);
      // let eDate = getServerDate(endDate);
      
      const res = await fetchApi({
        url : URLS.FETCH_DASHBOARD_DATA,

        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: {
          startDate, 
          endDate,
          affiliateUserIds : affiliateUsersList,
          allAffiliate,
          isAdmin
      },
      });

      let {isUserActive} = res;

      if(!isAdmin && isUserActive === false){
        setUserData(null);
      }
      return res;
   }else{
     return {};
   }
  }

  const { isLoading, error, data }  = useQuery([
    'dashboard',
   getServerDate(dates[0].startDate),
    getServerDate(dates[0].endDate),
    // dates[0].startDate,
    // dates[0].endDate,
    affiliateUsersList
  ], fetchDashboardData, {
    ...LONG_CACHE,
    retry: 1,
    refetchOnWindowFocus: true
  });

  // console.log({data, error, isLoading});

  if(isLoading === false && (!data || data.success === false)){
    return <span>Error Occurred {data.msg}</span>
  }

  return (
    <React.Fragment>
       {isLoading && <LinearProgress className="top-linear-progress"/>}
      <Grid container spacing={3} className="date-filter-ui">
        {/* <Grid container spacing={3}> */}
        {isAdmin && (
            <Grid item lg={4} xs={12}>
              <MultipleSelect 
                setLength = {setAffialiteLength}
                affilaiteLength={affilaiteLength}
                value={affiliateUsers}  
                changeHandler={changeAffiliates}/>
            </Grid>
         )}

          {/* </Grid> */}

        <Grid item md={6} xs={12}>
          <h3 className="center-align">Showing data from <b>{getWebsiteDate(dates[0].startDate)}</b> to <b>{getWebsiteDate(dates[0].endDate)}</b></h3>
        </Grid>

        <Grid item md={isAdmin ? 2 : 6} xs={12}>
          <DatePicker dates={dates} changeDates={changeDates}/>
        </Grid>
      </Grid>

      <Grid container spacing={isMobile ? 0 : 6} className="no-scroll no-top-margin">
        {DASHBOARD_CARDS.map((item, index) => {
          let {id, onlyAdmin, accessor, value} = item;
          if(!data){
            value = 0;
          }else{
                if(accessor === 'totalAffiliates'){
                  value = affilaiteLength;
                }else{
                  value = (isLoading || error) ? 0 : data?.dashboardData[accessor];
                }
          }

          if(onlyAdmin && !isAdmin){
                return null
          }
          return (
            <Grid item xs={12} sm={6} md={4} className="center-align dashboard--cards" key={id}>
              <Cards {...item} index={index} value={value}/>
            </Grid>
          )
        })}
      </Grid>

    </React.Fragment>
  )
}

export default Home