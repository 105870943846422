import React from 'react'
import {Grid, makeStyles} from '@material-ui/core';
import CardBox from '../components/Cards/CardBox';
import CardHeaderBox from '../components/Cards/CardHeaderBox';
import LoginIcon from '../components/Icons/LoginIcon';
import Form from '../components/Login/Form';
import {AuthContext} from '../context/Auth';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import EditProfileForm from '../components/Profile/EditProfileForm';
import Toastify from '../components/Toast/Toastify';
import ContactMailIcon from '@material-ui/icons/ContactMail';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      marginLeft: 20
    }
  },
  form: {
    width: '90%'
  }
}));

function EditProfile() {

  const {userData,setUserData} = React.useContext(AuthContext)

  return (
    <React.Fragment>
    <Grid>
      <div className="login-main-body edit-profile">
       
        <div className="login-children large-form">
          <CardBox className="login-card user-profile-label">
            <CardHeaderBox
              fullWidth={false}
              centerHalfWidth={true}
              title={<ContactMailIcon className = "card-icon" />}
              subheader={(
              <h5 className="white-text left-margin">Edit Profile</h5>
            )}/>
            <EditProfileForm userData={userData} setUserData={setUserData}/>
          </CardBox>
        </div>

        <div className="login-children">
          <CardBox className="login-card user-profile-label">
            <CardHeaderBox
              fullWidth={false}
              centerHalfWidth={true}
              title={<PhonelinkLockIcon className = "card-icon" />}
              subheader={(
              <h5 className="white-text left-margin">Mobile Number</h5>
            )}/>
            <Form userData={userData} setUserData={setUserData} isEdit={true}/>
          </CardBox>
        </div>
      </div>
    </Grid>
    <Toastify/>
    </React.Fragment>
  )
}

export default EditProfile
