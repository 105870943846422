const PRODUCTION = true;
// const PRODUCTION = false;
const CORS_PATH = 'https://cors-anywhere.herokuapp.com/';

const BASE_WEBSITE_PATH = {
    PRODUCTION: {
        INSIGHT: {
            FRONTEND: 'https://insightcosmetics.in/',
            BACKEND: 'https://backend.insightcosmetics.in/',
        },
        EYLINA: {
            FRONTEND: 'https://eylina.com/',
            BACKEND: 'https://backend.eylina.com/',
        },
        EMERCE: {
            FRONTEND: 'https://emerce.vaisansar.com/',
            BACKEND: 'https://emerce.backend.vaisansar.com/',
        },
        SEEKCAUS: {
            FRONTEND: 'https://seekcaus.com/',
            BACKEND: 'https://backend.seekcaus.com/',
        },
    },
    NONPRODUCTION: {
        INSIGHT: {
            FRONTEND: 'http://192.168.0.105:3001/',
            // FRONTEND: 'http://localhost:3001/',
            // BACKEND: 'http://localhost:9001/',
            BACKEND: 'http://192.168.0.101:9001/',
        },
        EYLINA: {
            FRONTEND: 'http://192.168.0.105:3001/',
            // FRONTEND: 'http://localhost:3001/',
            // BACKEND: 'http://localhost:9001/',
            BACKEND: 'http://192.168.0.101:9001/',
        },
        EMERCE: {
            FRONTEND: 'http://192.168.0.105:3001/',
            // FRONTEND: 'http://localhost:3001/',
            // BACKEND: 'http://localhost:9001/',
            BACKEND: 'http://192.168.0.101:9001/',
        },
        SEEKCAUS: {
            FRONTEND: 'http://192.168.0.104:3001/',
            // FRONTEND: 'http://localhost:3001/',
            // BACKEND: 'http://localhost:9001/',
            BACKEND: 'http://192.168.0.104:9001/',
        },
    },
};

exports.BASE_WEBSITE_PATH = BASE_WEBSITE_PATH[PRODUCTION ? 'PRODUCTION' : 'NONPRODUCTION'];

exports.PRODUCTION = PRODUCTION;
exports.CORS_PATH = CORS_PATH;