import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import GroupIcon from '@material-ui/icons/Group';

export const DASHBOARD_CARDS = [
    {
        id: 1,
        value: 0,
        subtitle: 'Total Orders',
        Icon: ShoppingCartIcon,
        onlyAdmin : true,
        accessor : 'totalOrders',
    },
    {
        id: 6,
        value:  0,
        subtitle: 'Revenue',
        Icon: AccountBalanceIcon,
        type : 'PAYMENT',
        onlyAdmin : true,
        accessor : 'revenue',
    },
    {
        id: 7,
        value:  0,
        subtitle: 'Commission',
        Icon: MonetizationOnIcon,
        type : 'PAYMENT',
        accessor : 'totalCommission',
    },
    {
        id: 8,
        value: 0,
        subtitle: 'Pending Payment',
        Icon: AccountBalanceWalletIcon,
        type : 'PAYMENT',
        accessor : 'pendingAmount',
    },
    {
        id: 4,
        value: 0,
        subtitle: 'Ordered Products',
        Icon: ShoppingBasketIcon,
        accessor : 'itemOrders'
    },
    {
        id: 2,
        value: 0,
        subtitle: 'Cancelled Products',
        Icon: RemoveShoppingCartIcon,
        // onlyAdmin : true,
        accessor : 'itemCancelled',
    },
    {
        id: 3,
        value: 0,
        subtitle: 'Returned Products',
        Icon: RemoveShoppingCartIcon,
        // onlyAdmin : true,
        accessor : 'itemReturned',
    },
    {
        id: 5,
        value: 50,
        subtitle: 'Delivered Products',
        Icon: LocalShippingIcon,
        accessor : 'itemDelivered',
    },
    {
        id: 9,
        value: 0,
        subtitle: 'Total Affiliates',
        Icon: GroupIcon,
        onlyAdmin : true,
        accessor : 'totalAffiliates',
    },
];
