import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Toastify() {
    return (
        <ToastContainer />
    )
}

export default Toastify
