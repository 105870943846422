import React from 'react'
import {IconButton} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';

function LinkIconButton({size = 'medium', platform}) {
  let iconTag = <OpenInNewIcon/>;
  if(platform){
    platform = platform.toUpperCase();
    if(platform === 'INSTAGRAM'){
      iconTag = <InstagramIcon/>;
    }else if(platform === 'YOUTUBE'){
      iconTag = <YouTubeIcon/>;
    }else if(platform === 'FACEBOOK'){
      iconTag = <FacebookIcon/>;
    }
  }
  
  return (
    <IconButton color="primary" aria-label="copy url" component="span" size={size}>
      {iconTag}
    </IconButton>
  )
}

export default LinkIconButton
