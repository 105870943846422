import React from 'react'
import {
  TextField,
  InputAdornment,
  makeStyles,
  Button,
  Grid,
  CircularProgress,
  Chip,
  Paper
} from '@material-ui/core';

import CardContentBox from '../Cards/CardContentBox';
import PersonIcon from '@material-ui/icons/Person';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SendIcon from '@material-ui/icons/Send';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkIcon from '@material-ui/icons/Link';
import {inputValidation, linkValidation, isNotNullOrEmpty, emailValidation} from '../../utils/validations';
import {displayToast} from '../../utils/displayToast';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { URLS } from '../../utils/routes';
import { fetchApi } from '../../utils/hitApi';
import { useMutation } from 'react-query';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    display: 'flex',
    flexDirection: 'column'
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    boxShadow: 'none',
    border: '1px solid gainsboro',
    margin: '5px'
  },
  chip: {
    margin: theme.spacing(0.5),
    height : 'auto'
    // marginRight : '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  txtFields: {
    margin: '15px 8px !important'
  }
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'inherit',
    textAlign: 'center',
    marginTop: '25px'
  },
  rootNew: {
    textTransform: 'inherit',
    textAlign: 'center',
    // marginTop: '25px'
  }
}));

const initialState = {
  showMainLoader: true,
  address: "",
  emailId: "",
  mobileNo: "",
  name: "",
  noOfVisitorsPerMonth: "",
  socialMediaUrls: [],
  mobileAppUrls: [],
  websiteUrls: [],
  youtube: "",
  instagram: "",
  facebook: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_FIELD':
      return {
        ...state,
        [action.fieldType]: action.value
      }

    case 'INIT':
      return {
        ...state,
        ...action.fields
      }

    default:
      return state;
  }
}

function EditProfile({userData,setUserData}) {

  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const showMainLoader = false;

  const [websiteUrlChipData,
    setWebsiteUrlChipData] = React.useState(userData
    ?.websiteUrls || []);
  const [singleLink,
    setSingleLink] = React.useState("");

  const [state,
    dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    let isActive = true;

    if (isActive) {
      let {socialMediaUrls} = userData;
      let instagram = "",
        facebook = "",
        youtube = "";
      socialMediaUrls.forEach(item => {
        let {platform, url} = item;
        let tempPlatform = platform.toUpperCase();
        if (tempPlatform === 'INSTAGRAM') {
          instagram = url;
        } else if (tempPlatform === 'FACEBOOK') {
          facebook = url;
        } else if (tempPlatform === 'YOUTUBE') {
          youtube = url;
        }
      })
      dispatch({
        type: 'INIT',
        fields: {
          ...userData,
          facebook,
          instagram,
          youtube
        }
      });
    }

    return () => {
      isActive = false;
    }
  }, [userData]);

  const {
    address, 
    emailId,
    // mobileNo,
    name,
    noOfVisitorsPerMonth,
    socialMediaUrls,
    instagram,
    facebook,
    youtube
    // mobileAppUrls, websiteUrls
  } = state;

  const formSubmit = (e) => {
    e.preventDefault();
    // console.log('form submission');
    handleUpdateProfile();
  }

  const hitProfileApi = async(body) =>{
    try {
      let res = await mutate({ body });
      let {success, msg} = res;

      let toastType = 'success';
      if(success){
        reset();
        setUserData({...userData, ...body});

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }else{
        toastType = 'error';
      }
      displayToast({toastMsg: msg, toastType});
      // form submission successful
    } catch (error) {
      displayToast({toastMsg: error.message, toastType : 'error'});
      // Uh oh, something went wrong
    }
  }
  
  const handleUpdateProfile = () =>{
    let tempSocialMediaUrl = [];
      if(!emailValidation(emailId)){
        return false;
      }else if(!inputValidation(noOfVisitorsPerMonth, 'monthly visitors')){
        return false;
      }else if(!inputValidation(address, 'address')){
        return false;
      }

      // if(instagram){
        if(!linkValidation(instagram, 'instagram')){
          return false;
        }else{
          tempSocialMediaUrl.push({
            platform : 'instagram',
            url : instagram
          })
        }
      // }

      if(facebook){
        if(!linkValidation(facebook, 'facebook')){
          return false;
        }else{
          tempSocialMediaUrl.push({
            platform : 'facebook',
            url : facebook
          })
        }
      }

      if(youtube){
        if(!linkValidation(youtube, 'youtube')){
          return false;
        }else{
          tempSocialMediaUrl.push({
            platform : 'youtube',
            url : youtube
          })
        }
      }

      let body = {
        affiliateUserId : userData.id,
        emailId,
        noOfVisitorsPerMonth,
        socialMediaUrls : tempSocialMediaUrl,
        websiteUrls : websiteUrlChipData,
        mobileAppUrls : [],
        address
      }
      hitProfileApi(body);
  }

  const handleDelete = (url) => {
    setWebsiteUrlChipData((chips) => chips.filter((chip) => chip.url !== url));
  }

  const addWebsiteUrl = () => {
    let url = singleLink;
    if (linkValidation(url, 'website link')) {
      if (websiteUrlChipData.find(item => item.url === url)) {
        displayToast({toastMsg: `Oops! ${url} is already added`, toastType: 'error'});
      } else {
        let data = {
          url
        };
        setWebsiteUrlChipData(prevUrl => ([
          ...prevUrl,
          data
        ]));
        setSingleLink("");
      }
    }
  }

  const changeLink = (e) => {
    let value = e.target.value;
    setSingleLink(value);
  }

  const handleChange = (event, fieldType) => {
    // setAge(event.target.value);
    let value = event.target.value;
    dispatch({type: 'CHANGE_FIELD', value, fieldType});
  };

  const submitWebsiteUrl = (e) => {
    let {charCode, which, keyCode} = e;
    if (keyCode === 13 || which === 13 || charCode === 13) {
      if (isNotNullOrEmpty(singleLink)) {
        addWebsiteUrl();
      }
    }
  }

  const handleUrlChange = (event, platform) => {
    let value = event.target.value;
    let newSocialMediaUrls = socialMediaUrls.map(item => {
      if (item.platform === platform) {
        item.url = value;
      }
      return item;
    });

    dispatch({type: 'CHANGE_FIELD', value: newSocialMediaUrls, fieldType: 'socialMediaUrls'});
  }

  const submitData = async({body}) =>{
    const url = URLS.UPDATE_PROFILE;
    const res = await fetchApi({
      url ,
      // Adding method type
      method: "POST",
      // Adding body or contents to send
      body,
    });
    return res;
  }

  const [mutate, { isLoading : formLoading, isError, isSuccess, status, data : formResponse, error, reset }] = useMutation(submitData);

  return (
    <CardContentBox className={showMainLoader
      ? 'center-align'
      : ''}>
      <form className={classes.root} onSubmit={formSubmit}>

        <TextField
          id="name"
          label="Name"
          variant="outlined"
          className={classes.txtFields}
          color="primary"
          value={name}
          InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <PersonIcon/>
            </InputAdornment>
          )
        }}/>

        <TextField
          id="email"
          label="E-mail"
          variant="outlined"
          color="primary"
          type="email"
          required
          className={classes.txtFields}
          onChange={(e) => handleChange(e, 'emailId')}
          value={emailId}
          InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AlternateEmailIcon/>
            </InputAdornment>
          )
        }}/>

        <TextField
          id="address"
          label="Address"
          variant="outlined"
          color="primary"
          multiline
          className={classes.txtFields}
          required
          rowsMax={4}
          value={address}
          onChange={(e) => handleChange(e, 'address')}
          InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <HomeWorkIcon/>
            </InputAdornment>
          )
        }}/>

        <TextField
          id='instagram'
          label='Instagram'
          variant="outlined"
          className={classes.txtFields}
          color="primary"
          required
          value={instagram}
          onChange={(e) => handleChange(e, 'instagram')}
          InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <InstagramIcon/>
            </InputAdornment>
          )
        }}/>

        <TextField
          id='facebook'
          label='Facebook'
          variant="outlined"
          className={classes.txtFields}
          color="primary"
          value={facebook}
          onChange={(e) => handleChange(e, 'facebook')}
          InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <FacebookIcon/>
            </InputAdornment>
          )
        }}/>

        <TextField
          id='youtube'
          label='Youtube'
          variant="outlined"
          className={classes.txtFields}
          color="primary"
          value={youtube}
          onChange={(e) => handleChange(e, 'youtube')}
          InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <YouTubeIcon/>
            </InputAdornment>
          )
        }}/>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="montly-visitors">Monthly Visitors</InputLabel>
          <Select
            labelId="montly-visitors"
            id="demo-simple-select-outlined"
            value={noOfVisitorsPerMonth}
            onChange={(e) => handleChange(e, 'noOfVisitorsPerMonth')}
            label="Monthly Visitors">
            <MenuItem value="Less than 1k">Less than 1k</MenuItem>
            <MenuItem value="1k to 5k">1k to 5k</MenuItem>
            <MenuItem value="5k-10k">5k-10k</MenuItem>
            <MenuItem value="10k-50k">10k-50k</MenuItem>
            <MenuItem value="50k-100k">50k-100k</MenuItem>
            <MenuItem value="100k+">100k+</MenuItem>
          </Select>
        </FormControl>

        <Grid container spacing={0} alignItems="center" className="no-spacing">
          <Grid item md={8} xs={12}>
            <TextField
              id="websiteUrls"
              label="Website Link"
              className={classes.txtFields}
              variant="outlined"
              color="primary"
              fullWidth
              value={singleLink}
              onChange={changeLink}
              onKeyPress={submitWebsiteUrl}
              InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LinkIcon/>
                </InputAdornment>
              )
            }}/>
          </Grid>
          <Grid item md={4} xs={12} className="center-align">
            <Button
              color="primary"
              className={buttonClasses.rootNew}
              variant="contained"
              onClick={addWebsiteUrl}
              endIcon={<SendIcon />}>
              Add Link
            </Button>
          </Grid>
        </Grid>
        <Paper component="ul" className={classes.chipRoot}>
          {websiteUrlChipData.map(item => {
            let {url} = item;
            return (<Chip
              key={url}
              label={url}
              variant="outlined"
              className={classes.chip}
              color="primary"
              onDelete={() => {
              handleDelete(url)
            }}/>)
          })}
        </Paper>

        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={6} className="center-align">
            <Button
              color="primary"
              className={buttonClasses.root}
              onClick={handleUpdateProfile}
              variant="contained"
              endIcon={<SendIcon />}>
              {formLoading ? <CircularProgress/> : 'Update Profile'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardContentBox>
  )
}

export default EditProfile
