import React, {useContext} from 'react'
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import EditProfile from '../pages/EditProfile';
import Home from "../pages/Home";
import Login from "../pages/Login";
import ManageAffiliate from '../pages/ManageAffiliate';
import ManagePayment from '../pages/ManagePayment';
import PendingAffiliates from '../pages/PendingAffiliates';
import GetProductLink from '../pages/GetProductLink';
import GetCategoryLink from '../pages/GetCategoryLink';
import ManageTable from '../pages/ManageTable';
import ManageAffiliates from '../pages/ManageAffiliates';
import PaymentHistory from '../pages/PaymentHistory';
import AddEditPayment from '../pages/AddEditPayment';

import NotFound from '../pages/NotFound';
import Layout from './Layout/Layout';
import {AuthContext} from '../context/Auth';
import ManageCoupons from '../pages/ManageCoupons';

function RouteWrapper({
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => <Layout {...props}>
      <Component {...props}/>
    </Layout>}/>
  );
}

function AdminRoutes() {
  return (
    <React.Fragment>
    {/* <RouteWrapper path="/pending-affiliate" component={PendingAffiliates}/> */}
      <RouteWrapper path="/manage-affiliates/" component={ManageAffiliates}/>
      <RouteWrapper path="/add-edit-payment/" component={AddEditPayment}/>
    </React.Fragment>
  )
}

function NonAdminRoutes() {
  return (
    <React.Fragment>
      <RouteWrapper path="/edit-profile/" component={EditProfile}/>
      <RouteWrapper path="/product-link/" component={GetProductLink}/>
      <RouteWrapper path="/category-link/" component={GetCategoryLink}/>
    </React.Fragment>
  )
}

function Nav() {
  const {isLoggedIn, isAdmin} = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Switch>
        {isLoggedIn
          ? (
            <React.Fragment>
              {/* <Redirect
                exact
                to={{
                pathname: "/home",
                state: {
                  from: '/'
                }
              }}/> */}
              
              <RouteWrapper path="/home/" component={Home}/> 
              {isAdmin ? (<AdminRoutes/>) : (<NonAdminRoutes/>)}

              <Route exact path="/">
                <Redirect to="/home" />
              </Route>

              <RouteWrapper path="/manage-payments/" component={ManagePayment}/>
              <RouteWrapper path="/payment-history/" component={PaymentHistory}/>
              <RouteWrapper path="/coupon-history/" component={ManageCoupons}/>
              
              {/* <Redirect from="/" to="/home" exact/> */}

              {/* <RouteWrapper path="/manage-table" component={ManageTable}/>  */}
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <Route path="/" exact component={Login}/>
              <Redirect to={{
                pathname: `/`
              }}/>
              {/* <Redirect to={{
                pathname: `/?brand=${window.localStorage.getItem('brand')}`
              }}/> */}
            </React.Fragment>
          )}
          
        <Route component={NotFound}/>
      </Switch>
    </BrowserRouter>
  )
}

export default Nav
