import React, {useContext} from 'react'
import {URLS} from '../utils/routes';
import {usePaginatedQuery} from 'react-query';
import {INITIAL_DATE_SETUP, LONG_CACHE, PAGE_SIZE,WEBSITE_TIME_DATE_FORMAT} from '../utils/constants';
import { useBrand } from '../hooks/useBrand';
import { AuthContext } from '../context/Auth';
import TableComponent from '../components/Tables/TableComponent';
import { SerialNumberHeader } from '../components/Tables/SerialNumberHeader';
import { Button } from '@material-ui/core';
import dayjs from 'dayjs';
// import {subMonths} from 'date-fns';
import DatePicker from '../components/DatePicker';
import LinkTag from '../components/LinkComponents/LinkTag';
import LinkIconButton from '../components/LinkComponents/LinkIconButton';

// import Modal from '../components/Modal/Modal';
import swal from 'sweetalert';
import { isNotNullOrEmpty } from '../utils/validations';
import Toastify from '../components/Toast/Toastify';
// import { toast } from 'react-toastify';
import { displayToast } from '../utils/displayToast';
import { fetchApi } from '../utils/hitApi';
// import {useParams} from 'react-router-dom';
import { useRouterQuery } from '../hooks/useRouterQuery';
import UserCard from '../components/Tables/UserCard';
import { getDates, getServerDate, makeValidLink } from '../utils/functions';
import { NumberCell } from '../components/Tables/NumberCell';
import AddressCard from '../components/Tables/AddressCard';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  cancelButton : {
    backgroundColor : theme.palette.secondary.main,
    color : theme.palette.common.white
  },
  approveButton : {
    backgroundColor : theme.palette.primary.main,
    color : theme.palette.common.white
  }
}));

let pageSize = PAGE_SIZE;
// let pageSize = 1;
const listName = "affiliateUserList";

function ManageAffiliates() {
const classes = useStyles();
// let { status } = useParams();
const pageData = React.useRef([]);

const query = useRouterQuery();

const status = query.get("status");

  const [page, setPage] = React.useState(1);

  const [dates, setDates] = React.useState(INITIAL_DATE_SETUP);

  const [searchedTerm, setSearchdTerm] = React.useState("");

  const [removeList, setRemoveList] = React.useState([]);
    
    const {userData,isAdmin} = useContext(AuthContext);

    const [brandName] = useBrand();

    React.useEffect(() => {
      if(removeList.length > 0){
        setRemoveList([]);
      }
    }, [status]);

    const fetchAffiliates = async(key, pageNo = 1, pageSize, startDate, endDate, status, searchedTerm) => {
      // console.log({isFetching, isLoading});
      if(!!startDate && !!pageNo && !!pageSize && !!endDate){
        // console.log('hit api', {isFetching, isLoading, status});
        // let sDate = getServerDate(startDate);
        // let eDate = getServerDate(endDate);

        const res = await fetchApi({
          url : URLS.FETCH_AFFILIATE_USERS,
  
          // Adding method type
          method: "POST",
  
          // Adding body or contents to send
          body: {
            "pageNo": pageNo, 
            "pageSize": pageSize, 
            "searchTerm": searchedTerm, 
            status, 
            startDate, 
            endDate
            // startDate : sDate, 
            // endDate : eDate
        },
  
          // Adding headers to the request
          // headers: {
          //   "Content-type": "application/json; charset=UTF-8"
          // }
        });
        // const result = await res.json();
        return res;
     }else{
       return {};
     }
    }
  
    const increasePageCount = React.useCallback(
      (pageNo,newPageSize) => {
        // if(pageNo > 1){
          setPage(pageNo);
          pageSize = newPageSize;
        // }
      },
      [],
    );
  
    const changeSearchedTerm = React.useCallback(
      (value) => {
        setSearchdTerm(value);
        setPage(1);
      },
      [],
    );

    const {
      isLoading,
      isError,
      error,
      resolvedData,
      latestData,
      isFetching
    } = usePaginatedQuery([
      'affiliateList', page, pageSize, 
      // dates[0].startDate,
      // dates[0].endDate,
      getServerDate(dates[0].startDate),
      getServerDate(dates[0].endDate),
      // dayjs(dates[0].startDate).format(SERVER_DATE_FORMAT), 
      // dayjs(dates[0].endDate).format(SERVER_DATE_FORMAT), 
      status,
      searchedTerm
    ], fetchAffiliates, {
      // ...LONG_CACHE,
      retry: 1,
      refetchOnWindowFocus: false
    });

    // console.log('render :', {isFetching, isLoading});

    const changeDates = React.useCallback(
      (data) => {
        // console.log(data);
        setDates(data)
      },
      [],
    )

    const changeStatus = (id, caseType, name) =>{
      let swalTxt = "";
      if(caseType === 'APPROVE'){
        swalTxt = `Enter % commission you want to give to ${name}`;
      }else{
        swalTxt = `Please enter reason to reject ${name}`;
      }

      swal({
        text: swalTxt,
        content: "input",
        dangerMode: true,
        buttons: {
          // cancel: 'Cancel',
          cancel: {
            text: "Cancel",
            value: null,
            visible: true,
            className: clsx(classes.cancelButton),
            closeModal: true,
          },
          confirm: {
            text: caseType === 'APPROVE' ? "Approve" : "Reject",
            value: true,
            visible: true,
            className : clsx(classes.approveButton),
            closeModal: false
          }
          // catch: {
          //   text: caseType === 'APPROVE' ? "Approve" : "Reject",
          //   closeModal: false,
          //   className : clsx(classes.approveButton)
          // }
        },
      }).then(inputValue => {
        let isModalActive = swal.getState();
        let {isOpen} = isModalActive;
      if(isOpen){
        let isValidValue = false, commission, rejectReason = null, errorMsg = "";
        if(caseType === 'APPROVE'){
          commission = parseFloat(inputValue);
          if(isNotNullOrEmpty(commission) && !isNaN(commission) && /[0-9]*/g.test(commission) && commission <= 100){
            isValidValue = true;
          }else{
            if(isNaN(commission)){
              errorMsg = 'Only numbers allowed';
            }else if(!commission <= 100){
              errorMsg = 'Commisison percentage should be less than 100';
            }else{
              errorMsg = 'Please enter valid commission percentage';
            }
          }
        }else{
          rejectReason = inputValue;
          if(isNotNullOrEmpty(rejectReason)){
            isValidValue = true;
          }else{
            errorMsg = 'Please enter valid rejection reason';
          }
        }
        if(isValidValue === false){
          displayToast({toastMsg : errorMsg, toastType : 'error'});
          swal.stopLoading();
        }else{
          let body = {
            affiliateUserId : id,
            status : caseType === 'APPROVE' ? 'APPROVED' : 'REJECTED' ,
            commission,
            rejectReason
          }
          return updateUserStatus(body);
        }
      }
      }).then(res =>{
        swal.stopLoading();
        if(res){
            let {success, msg} = res;
            if(success){
              setRemoveList(prevList => ([...prevList, id]));
              swal.close();
            }
            if(success){
              msg = `${name} is ${caseType === 'APPROVE' ? 'approved' : 'rejected'} as a affiliate user successfully`;
            }
            // displayToast({toastMsg : `${name} is approved as a affiliate user successfully`, toastType : 'success'});
            displayToast({toastMsg : msg, toastType : success ? 'success' : 'error'});
        }
      });
    }

    const changeStatus1 = (id, caseType, name) =>{
      if(caseType === 'APPROVE'){
        swal({
          text: `Enter % commission you want to give to ${name}`,
          content: "input",
          button: {
            text: "Submit",
            closeModal: false,
          },
        }).then(commission => {
          if(isNotNullOrEmpty(commission) && /[0-9]*/g.test(parseFloat(commission)) && parseFloat(commission) <= 100){
            let body = {
              affiliateUserId : id,
              status : caseType,
              commission,
              rejectReason : ""
            };
            return updateUserStatus(body);
          }else{
            displayToast({toastMsg : 'Please enter valid commission percentage', toastType : 'error'});
              swal.stopLoading();
          }
        }).then(res =>{
          swal.stopLoading();
          let {success, msg} = res;
          if(success){
            setRemoveList(prevList => ([...prevList, id]));
            swal.close();
          }
          // displayToast({toastMsg : `${name} is approved as a affiliate user successfully`, toastType : 'success'});
          displayToast({toastMsg : msg, toastType : success ? 'success' : 'error'});
        });
      }else{
        swal({
          text: `Please enter reason to reject ${name}`,
          content: "input",
          button: {
            text: "Submit",
            closeModal: false,
          },
        }).then(reason => {
          if(isNotNullOrEmpty(reason)){
            setRemoveList(prevList => ([...prevList, id]));
            swal.stopLoading();
            swal.close();
            displayToast({toastMsg : `${name} is rejected successfully.`, toastType : 'success'});
          }else{
            displayToast({toastMsg : 'Please enter valid rejection reason', toastType : 'error'});
              swal.stopLoading();
          }
        });
      }
    }

    const updateUserStatus = async(body) =>{
      
        let res = await fetchApi({
          body, 
          url : URLS.CHANGE_AFFILIATE_STATUS, 
          // headers: {
          //   "Content-type": "application/json; charset=UTF-8"
          // },
          method : 'POST'
        });
        return res;
    }

    const userChangeApi = async(id,isActive) =>{
      const res = await fetchApi({
          url : URLS.CHANGE_USER_ACTIVE_STATUS ,
          // Adding method type
          method: "POST",
          // Adding body or contents to send
          body : {
            affiliateUserId : id,
            activeStatus : !isActive
          },
        });
        let {success, msg} = res;
        let toastType = success ? 'success' : 'error';
  
        if(success){
          // removeList(paymentId);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
  
        displayToast({toastMsg : msg, toastType});
        return res;
    }

    const changeActiveStatus = async (id, isActive, name) => {
      swal({
          title: "Are you sure?",
          text: `You want to ${isActive ? 'deactivate' : 'activate'} ${name}`,
          icon: "warning",
          // buttons: true,
          buttons : {
            cancel: {
              text: "Cancel",
              value: null,
              visible: true,
              className: clsx(classes.cancelButton),
              closeModal: true,
            },
            confirm: {
              text: 'Submit',
              value: true,
              visible: true,
              className : clsx(classes.approveButton),
              closeModal: true
            },
          },
          dangerMode: true,
        })
        .then((willDelete) => {
          // debugger;
          if (willDelete) {
              return userChangeApi(id,isActive);
          } else {
              swal.stopLoading();
              swal.close();
          }
        }).then(res =>{
          swal.stopLoading();
        });
    }

    const columns = React.useMemo(() => [
      // SerialNumberHeader,
      {
        Header: 'Name',
        accessor: 'name'
      }, 
      {
        Header: 'Mobile Number',
        accessor: 'mobileNo'
      },
      {
        Header: 'E-mail',
        accessor: 'emailId'
      },
      {
        Header: 'Details',
        accessor: 'details'
      },
      {
        Header: 'Address',
        accessor: 'address',
        Cell: ({
          cell: {
              value
          }
      }) => <AddressCard value={value}/>
      },
      {
        Header: 'Date',
        accessor: 'regDate'
      },
      {
        Header: 'Monthly visitors',
        accessor: 'noOfVisitors'
      },
     
    ], [status]);

    const columnsNew = React.useMemo(() => [
      SerialNumberHeader,
      {
        Header: 'Name',
        // accessor: 'name',
        id : 'name',
        Cell: ({row}) => {
          let {original} = row;
            return <UserCard {...original}/>
        }
      }, 
      {
        Header: 'Revenue',
        accessor: 'revenue',
        canBeFiltered : true,
        ...NumberCell
      },
      {
        Header: '% Commission',
        accessor: 'percentageCommission',
        // canBeFiltered : true,
        Cell: ({
          cell: {
              value
          }
        }) => (
            <span>{parseFloat(value)}%</span>
        )
      },
      {
        Header: 'Commission Amount',
        accessor: 'commissionAmount',
        canBeFiltered : true,
        ...NumberCell
      },
      {
        Header: 'Processing', 
        accessor: 'pendingCommissionAmount',
        canBeFiltered : true,
        ...NumberCell
      },
      {
        Header: 'Completed', 
        accessor: 'computedCommissionAmount',
        canBeFiltered : true,
        ...NumberCell
      },
      {
        Header: 'Paid',
        // accessor: 'name',
        id : 'paidAmount',
        Cell: ({row}) => {
          let {original} = row;
          // console.log({original});
          let {id, paidAmount, name} = original;
            const link = `/manage-payments?id=${id}&name=${name.split(' ').join('-')}`;
            return (<Link to={link}>
                      <Button size="small" variant="outlined" color="primary">
                      &#8377; {parseFloat(paidAmount)}
                      </Button>
                    </Link>)
        }
      }, 
      {
        Header: 'Unpaid', 
        accessor: 'pendingAmount',
        // canBeFiltered : true,
        ...NumberCell
      },
      {
        Header: 'Total Commission Amt.',
        accessor: 'totalCommissionAmount',
        ...NumberCell
      },
      {
        Header: () => 'Action',
        id: 'action', // It needs an ID
        Cell: ({row}) => { 
          let {original} = row;
          let {id, name, isActive} = original;

          return(<Button size="small" variant="contained" color={isActive ?  'secondary' : "primary"} 
          onClick={()=>{changeActiveStatus(id, isActive, name)}}>
           {isActive ? 'Inactivate' : ' Activate '}
        </Button>)
        }
      }
    ], [status]);

    const pendingColumns = React.useMemo(() => [
      ...columns,
      {
        Header: () => 'Action',
        id: 'action', // It needs an ID
        Cell: ({row}) => { 
          let {original} = row;
          let {id, name} = original;
          if(status === 'PENDING'){
            return (
              <div className="evenly-spaced-button">
              <Button size="small" variant="contained" color="primary" onClick={()=>{changeStatus(id, 'APPROVE', name)}}>
                Approve
              </Button>
              <Button size="small" variant="contained" color="secondary" onClick={()=>{changeStatus(id, 'REJECT', name)}}>
              &nbsp;Reject&nbsp;
              </Button>
            </div>
            )
          }else{
            return (<p>{status}</p>)
          }
        }
      }
    ], [status]);

    const rejectedColumns = React.useMemo(() => [
      ...columns,
      {
        Header: 'Reject Date',
        accessor: 'rejectedDate',
      //   Cell: ({
      //     cell: {
      //         value
      //     }
      // }) => <span>{value}</span>
      },
      {
        Header: 'Reason',
        accessor: 'rejectReason',
        Cell: ({
          cell: {
              value
          }
      }) => <AddressCard value={value} label="Reject Reason" />
      },
    ], [status]);

    if(isError){
      return <div>Error occurred</div>
    }

    // console.log({latestData});
    // console.log({resolvedData});

    if(resolvedData && Object.keys(resolvedData).length > 0){
      pageData.current[page] = resolvedData[listName];
  }

  return (
    <React.Fragment>
      {status === 'APPROVED' && (<DatePicker dates={dates} changeDates={changeDates} showSubTxt={true}/>)}

    <TableComponent columns={status === 'APPROVED' ? columnsNew : status === 'PENDING' ? pendingColumns : rejectedColumns} 
        // data={isError ? [] : resolvedData}
        data={resolvedData ? {[listName] : pageData.current[page], totalRecords : resolvedData.totalRecords} : []}
        // data = {[]}
        fetchData={increasePageCount}
        loading={isLoading}
        // pageCount={1000}
        brandName={brandName}
        userData={userData}
        changeKey = {null}
        initialPage = {1}
        currentPageNumber = {page}
        manualSearch = {true}
        manualPagination = {true}
        changeSearchedTerm = {changeSearchedTerm}
        modifyTableData = {modifyTableData}
        subTitle = {`${status} Affiliates`}
        tableTitle = {getDates(dates)}
        listName = {listName}
        removeList={removeList}
        currentPageSize = {pageSize}
        isFetching = {isFetching}
        changeColumnColor={true}
        columnColorKey='isActive'
        />
        <Toastify/>
        </React.Fragment>
  )
}

function modifyTableData(list){
  let tableData = list.map((item) =>{
    let {registrationDate, websiteUrls = [], mobileAppUrls = [], socialMediaUrls = [], rejectedDate} = item;
    let details = [...socialMediaUrls, ...websiteUrls, ...mobileAppUrls].map(link => {
        // return <span key={link.url}>{link.platform ? <b>{link.platform} : </b> : ""} {link.url}<br/></span>
      let itemLink = link.url;
      let tempLink = makeValidLink(itemLink);
      const truncateLink = `${tempLink.substring(0,15)}..`;
        return (
          <LinkTag itemLink={tempLink} key={tempLink}>
            <LinkIconButton size="small" platform={link.platform}/>
             {/* {link.platform ? <b>{link.platform}</b> : <AddressCard label={tempLink} value={tempLink}/>} */}
             {link.platform ? <b>{link.platform}</b> : truncateLink}
            <br/>
          </LinkTag>
        )
    });

    let date = dayjs(registrationDate).format(WEBSITE_TIME_DATE_FORMAT);
    let rDate = dayjs(rejectedDate).format(WEBSITE_TIME_DATE_FORMAT);

    return({
      ...item,
      details : details,
      date : registrationDate,
      rejectedDate : rejectedDate ? rDate : null,
      regDate : date
    });

  });
  return tableData;
}

export default ManageAffiliates
