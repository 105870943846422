import React from 'react'
import {useQuery} from 'react-query'
import {LONG_CACHE} from '../utils/constants';
import {fetchApi} from '../utils/hitApi';
import {URLS} from '../utils/routes';
import {Skeleton} from '@material-ui/lab';
import VerticalTabs from './Tabs/VerticalTabs';
let url = URLS.GET_PAYMENT_METHODS;

function PaymentMethods({paymentData, affiliateUserInvoiceId, status}) {

  const fetchPaymentMethods = async() => {
    const res = await fetchApi({
      url,
      // Adding method type
      method: "GET"
    });
    return res;
  }

  const {isLoading, error, data} = useQuery('paymentMethods', fetchPaymentMethods, {
    ...LONG_CACHE,
    refetchOnWindowFocus: false,
    retry : 1
  });

  // console.log({paymentData}, 'paymentData in PaymentMethods');

  return (
    <div>
      {isLoading ? (
        <React.Fragment>
          <Skeleton/>
          <Skeleton/>
          <Skeleton/>
        </React.Fragment>
      ) : (<VerticalTabs status={status} data={data} paymentData={paymentData} affiliateUserInvoiceId={affiliateUserInvoiceId}/>)}
    </div>
  )
}

export default PaymentMethods
