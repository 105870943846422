import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paid : {
        backgroundColor: theme.palette.success.light,
    },
    unpaid : {
        backgroundColor: theme.palette.error.light,
        color : '#fff'
    },
    partial : {
        backgroundColor: theme.palette.warning.light,
    },
  }));

function StatusChips({value}) {
    const classes = useStyles();
    let color = 'unpaid';
    if(value === 'Partially'){
        color = 'partial';
    }else if(value === 'Paid'){
        color = 'paid';
    };
    return (
        <Chip label={value} className={classes[color]}/>
    )
}

export default StatusChips
