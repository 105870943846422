import {
  Card,
  CardContent,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  DialogActions
} from '@material-ui/core'
import React, {useState} from 'react'
import LinkTag from '../LinkComponents/LinkTag';
import LinkIconButton from '../LinkComponents/LinkIconButton';
import { getWebsiteDate, makeValidLink } from '../../utils/functions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AuthContext } from '../../context/Auth';
import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  secondary : {
    backgroundColor : theme.palette.secondaryError.light
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  centerAlign : {
    textAlign : 'center',
    justifyContent : 'center'
  }
}))

function UserCard(props) {
  const classes = useStyles();

  const {isAdmin} = React.useContext(AuthContext);

  let {
    address,
    details,
    mobileNo,
    userCode,
    emailId,
    noOfVisitors,
    name,
    date,
    rejectedDate,
    rejectReason,
    socialMediaUrls = [],
    websiteUrls = [],
    mobileAppUrls = [],
    isActive = true
  } = props;

  let urlDetails = [...socialMediaUrls, ...websiteUrls, ...mobileAppUrls].map(link => {      
    let itemLink = link.url;
    
    let tempLink = makeValidLink(itemLink);
    // let tempLink = itemLink;
    // if(!/http(s)*:\/\//.test(itemLink)){
    //   tempLink = `https://${itemLink}`;
    // }
    // console.log({itemLink, tempLink});
      return (
        <LinkTag itemLink={tempLink} key={tempLink}>
          <LinkIconButton size="small" platform={link.platform}/> {link.platform ? <b>{link.platform}</b> : `${tempLink.substring(0,15)}..`}
          <br/>
        </LinkTag>
      )
  });

  let registrationDate = getWebsiteDate(date);

  const [open,
    setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log({rejectedDate});

  return (
    <React.Fragment>
      <Button variant="outlined" size="small" color="primary" onClick={handleClickOpen}>
        {name}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Affiliate User Details
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  {name}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Address
                </TableCell>
                <TableCell>
                  {address}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Details
                </TableCell>
                <TableCell>
                  {urlDetails}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  E-mail Id
                </TableCell>
                <TableCell>
                  {emailId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Mobile Number
                </TableCell>
                <TableCell>
                  {mobileNo}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Monthly Visitors
                </TableCell>
                <TableCell>
                  {noOfVisitors}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  User Code
                </TableCell>
                <TableCell>
                  {userCode}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Registration Date
                </TableCell>
                <TableCell>
                  {registrationDate}
                </TableCell>
              </TableRow>

              <TableRow className={!isActive && classes.secondary}>
                <TableCell>
                 User Status
                </TableCell>
                <TableCell>
                  {isActive ? 'Active' : 'Inactive'}
                </TableCell>
              </TableRow>
              
              {rejectReason && (
                <TableRow>
                <TableCell>
                  Rejection Reason
                </TableCell>
                <TableCell>
                  {rejectReason}
                </TableCell>
              </TableRow>
              )}

              {rejectedDate && (
                <TableRow>
                <TableCell>
                  Rejection Date
                </TableCell>
                <TableCell>
                  {rejectedDate}
                </TableCell>
              </TableRow>
              )}
              
            </TableHead>
          </Table>
        </TableContainer>
        </DialogContent>
        <DialogActions className={classes.centerAlign}>
        {!isAdmin && (
            <Link to="/edit-profile">
                <Button color="primary" variant="contained">
                    Edit Profile
              </Button>
            </Link>
        )}
          <Button onClick={handleClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default UserCard
