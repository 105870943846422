import React, {useEffect} from 'react'
import CardBox from '../components/Cards/CardBox';
import CardHeaderBox from '../components/Cards/CardHeaderBox';
import LoginIcon from '../components/Icons/LoginIcon';
import Form from '../components/Login/Form';
import '../styles/login.scss';
import { useLocalStorageState } from '../hooks/useLocalStorageHook';

function Login() {

  const [userData, setUserData] = useLocalStorageState('userData', null);

  /*const [{ data, loading, error }, refetch] = useAxios({
    url:  URLS.GET_OTP_TO_LOGIN,
    method : 'post',
    data : {
      mobileNo : "9324006186"
    }
  });*/

  // const [{ data, loading, error }, sendOtpToUser] = useAxios(
  //   {
  //     url: URLS.GET_OTP_TO_LOGIN,
  //     method: 'POST',
  //   },
  //   { manual: true }
  // )

  // const getOtpForUser = async (mobileNo) => {
  //   const res = await sendOtpToUser({
  //     data: {
  //       mobileNo
  //     },
  //   });
  // }
  
  /*const [{data : userData}, sendOtpToUser] = useAxios({
    url:  URLS.GET_OTP_TO_LOGIN,
    method : 'post',
    data : {
      mobileNo
    }
  });*/

  return (
    <div className="login-main-body">
      <div className="login-children">
        <CardBox className="login-card">
          <CardHeaderBox
          fullWidth = {true}
            title={<LoginIcon viewBox = "0 0 512 512" className="card-icon"/>}
            subheader={(<h3 className="white-text">Login</h3>)}
            />
          <Form userData={userData} setUserData={setUserData} isEdit={false}/>
        </CardBox>
      </div>
    </div>
  )
}

export default Login
