import React from 'react'

import {CssBaseline, makeStyles} from '@material-ui/core';
import Sidebar from './Sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    overflowX: 'auto'
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    marginBottom : '30px'
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    // minHeight : '30px !important'
  }
}));

function Layout({children}) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <CssBaseline/>
        <Sidebar/>
        <main className={classes.content}>
          <div className={classes.toolbar}/> 
          {children}
        </main>
      </div>
  )
}

export default Layout;
