import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CategoryIcon from '@material-ui/icons/Category';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CancelIcon from '@material-ui/icons/Cancel';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import HistoryIcon from '@material-ui/icons/History';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const APP_ROUTES = [{
    id: 1,
    title: 'Dashboard',
    path: '/home',
    Icon: Dashboard
}, {
    id: 2,
    title: 'Profile',
    path: '/edit-profile',
    Icon: Person,
    isInfluencerPath: true
},
{
    id: 3,
    title: 'Pending Affiliates',
    // path: '/pending-affiliates/PENDING',
    // path: '/pending-affiliate',
    path: '/manage-affiliates?status=PENDING',
    isAdminPath: true,
    Icon: NewReleasesIcon
},
{
    id: 4,
    title: 'Approved Affiliates',
    // path: '/approved-affiliates/APPROVED',
    path: '/manage-affiliates?status=APPROVED',
    isAdminPath: true,
    Icon: DoneAllIcon
},
{
    id: 5,
    title: 'Rejected Affiliates',
    // path: '/rejected-affiliates/REJECTED',
    path: '/manage-affiliates?status=REJECTED',
    isAdminPath: true,
    Icon: CancelIcon
},
// {
//     id: 6,
//     title: 'Manage Affiliate',
//     path: '/manage-affiliate',
//     isAdminPath: true,
//     Icon: AssignmentIndIcon
// }, 
{
    id: 7,
    title: 'Manage Payments',
    path: '/manage-payments',
    Icon: AccountBalanceWalletIcon,
    isAdminPath: true,
},
{
    id: 8,
    title: 'Payment History',
    path: '/payment-history',
    Icon: HistoryIcon,
    isInfluencerPath: true
},
{
    id: 9,
    title: 'Get Product Links',
    path: '/product-link',
    Icon: ShoppingBasketIcon,
    isInfluencerPath: true
},
{
    id: 10,
    title: 'Get Category Links',
    path: '/category-link',
    Icon: CategoryIcon,
    isInfluencerPath: true
},
{
    id: 11,
    title: 'Coupon History',
    path: '/coupon-history',
    Icon: ReceiptIcon,
},
    // {
    //     id: 8,
    //     title: 'Manage Table',
    //     path: '/manage-table',
    //     Icon: AccountBalanceWalletIcon,
    // },
];