import React from 'react';
import { CardContent } from '@material-ui/core';

function CardContentBox(props) {
    return (
        <CardContent>
          {props.children}
        </CardContent>
    )
}

export default CardContentBox
