import { subMonths } from "date-fns";

const expiryTime = 24 * 365 * 60 * 60 * 1000;

const LONG_CACHE = {
    staleTime: 20 * 60 * 60,
    cacheTime: 10 * 60 * 60,
}

const PAGE_SIZE = 10;

const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
const WEBSITE_DATE_FORMAT = `D MMM, YYYY`;
const WEBSITE_TIME_DATE_FORMAT = `D MMM, 'YY - hh:mm A`;

const INITIAL_DATE_SETUP = [
    {
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
      key: 'selection'
    }
  ];

const ALL_OPTIONS = {id : -1, name : 'All'};

export {expiryTime, LONG_CACHE, PAGE_SIZE, SERVER_DATE_FORMAT, WEBSITE_DATE_FORMAT, WEBSITE_TIME_DATE_FORMAT, INITIAL_DATE_SETUP, ALL_OPTIONS};
