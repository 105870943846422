import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mainTxt : {
    color : theme.palette.primary.main,
    fontSize : 18
  },
  txtMargin : {
      marginTop : 10,
      marginBottom : 10,
  }
}));

function SubText() {

    const classes = useStyles();

    return (
        <h3 className={classes.txtMargin}>Only fields marked with <span className={classes.mainTxt}>*</span> will change with date filter</h3>
    )
}

export default SubText
