import React from 'react'
import {usePaginatedQuery} from 'react-query';
import DatePicker from '../components/DatePicker'
import MultipleSelect from '../components/MultiSelect/MultipleSelect';
import {Grid, Button} from '@material-ui/core';
import { ALL_OPTIONS, INITIAL_DATE_SETUP, LONG_CACHE, PAGE_SIZE } from '../utils/constants';
import { AuthContext } from '../context/Auth';
import UserCard from '../components/Tables/UserCard';
import { SerialNumberHeader } from '../components/Tables/SerialNumberHeader';
import Toastify from '../components/Toast/Toastify';
import { getDates, getServerDate, getWebsiteDate } from '../utils/functions';
import TableComponent from '../components/Tables/TableComponent';
import { URLS } from '../utils/routes';
import { fetchApi } from '../utils/hitApi';
import CustomSelect from '../components/Select/CustomSelect';
import { Link } from 'react-router-dom';
import {NumberCell} from '../components/Tables/NumberCell';
import StatusChips from '../components/Tables/StatusChips';
import PaymentCard from '../components/Tables/PaymentCard';

let pageSize = PAGE_SIZE;
const listName = "paymentHistoryList";

function ManagePayment() {
  const pageData = React.useRef([]);
  
  const {isAdmin, userData} = React.useContext(AuthContext);
  const [dates, setDates] = React.useState(INITIAL_DATE_SETUP);
  const [page, setPage] = React.useState(1);

  const [affiliateUsers, setAffiliateUsers] = React.useState([ALL_OPTIONS]);

  const [affiliateUsersList, setAffiliateUsersList] = React.useState(()=> isAdmin ? [] : [userData.id]);

  const [allAffiliate, setAllAffiliate] = React.useState(isAdmin);

  const changeDates = React.useCallback(
    (data) => {
      setDates(data)
    },
    [],
  )

  const changeAffiliates = React.useCallback(
    (data) => {
      setAffiliateUsers(data);
      let isAllSelected = false;
      let list = data.map(item => {
        if(item.id === -1){
          isAllSelected = true;
        }
        return item.id
      });
      setAffiliateUsersList(isAllSelected ? [] : list);
      setAllAffiliate(isAllSelected);
    },
    [],
  )

  const columnsNew = React.useMemo(() => [
    SerialNumberHeader,
    {
      Header: 'Invoice No.',
      accessor: 'invoiceNumber'
    },
    {
      Header: 'Name',
      accessor : 'affiliateName'
    }, 
    {
      Header: 'Paid Amount', 
      id: 'paidAmount',
      Cell: ({row}) => {
        let {original} = row;
        let {paidAmount} = original;
          return <span>&#8377; {paidAmount}</span>
      }
    },
    {
      Header: 'Payment Date', 
      accessor: 'date',
      Cell: ({
        cell: {
            value
        }
    }) => (
      <span>{getWebsiteDate(value)}</span>
    )
  }
  ], []);

  const increasePageCount = React.useCallback(
    (pageNo,newPageSize) => {
      // if(pageNo > 1){
        // console.log('setting : ', {pageNo, newPageSize});
        setPage(pageNo);
        pageSize = newPageSize;
      // }
    },
    [],
  );

  const fetchPaymentHistory = async(key, pageNo = 1, pageSize, startDate, endDate, affiliateUsersList) =>{
    if(!!startDate && !!pageNo && !!pageSize && !!endDate){
      // let sDate = getServerDate(startDate);
      // let eDate = getServerDate(endDate);
      const res = await fetchApi({
        url : URLS.FETCH_PAYMENT_HISTORY,

        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: {
          "pageNo": pageNo, 
          "pageSize": pageSize, 
          startDate, 
          endDate,
          affiliateUserIds : affiliateUsersList,
          allAffiliate
      },
      });
      return res;
   }else{
     return {};
   }
  }

  const {
    isLoading,
    isError,
    error,
    resolvedData,
    latestData,
    isFetching
  } = usePaginatedQuery([
    'paymentHistoryList', page, pageSize, 
    getServerDate(dates[0].startDate),
    getServerDate(dates[0].endDate),
    affiliateUsersList
  ], fetchPaymentHistory, {
    ...LONG_CACHE,
    retry: 1,
    refetchOnWindowFocus: false
  });

  if(resolvedData && Object.keys(resolvedData).length > 0){
    pageData.current[page] = resolvedData[listName];
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>

      {isAdmin && (
        <Grid item md={6} xs={12}>
          <MultipleSelect value={affiliateUsers} changeHandler={changeAffiliates}/>
        </Grid>
      )}

        <Grid item xs={12} md={isAdmin ? 6 : 12}>
          <DatePicker dates={dates} changeDates={changeDates}/>
        </Grid>
      </Grid>

      <TableComponent 
        columns={columnsNew} 
        // data={isError ? [] : resolvedData}
        data={resolvedData ? {[listName] : pageData.current[page], totalRecords : resolvedData.totalRecords} : []}
        fetchData={increasePageCount}
        loading={isLoading}
        changeKey = {affiliateUsers}
        initialPage = {1}
        currentPageNumber = {page}
        manualSearch = {true}
        manualPagination = {true}        
        modifyTableData = {modifyTableData}
        subTitle = {`Payment History`}
        tableTitle = {getDates(dates)}
        listName = {listName}
        currentPageSize = {pageSize}
        isFetching = {isFetching}
        changeKeyOne={null}
        hideSearch={true}
        />

        <Toastify/>
    </React.Fragment>
  )
}

function modifyTableData(list){
  return list;
}

export default ManagePayment
