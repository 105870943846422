import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props}/>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

function CopyTxt(props) {
  const classes = useStyles();
  const {label = 'Link'} = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const txtCopied = () => {
    setOpen(true);
  }

  return (
    <React.Fragment>
      <CopyToClipboard text={props.value} onCopy={txtCopied}>
        {props.children}
      </CopyToClipboard>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} 
      anchorOrigin={{ vertical: 'top', horizontal: 'center'  }}
      >
        <Alert onClose={handleClose} severity="success">
          {label} copied successfully!
        </Alert>
      </Snackbar>

    </React.Fragment>
  )
}

export default CopyTxt
