import React from 'react';
import {Card, CardContent, makeStyles, Typography, Grid, CardHeader} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PersonIcon from '@material-ui/icons/Person';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 18
  },
  subheader : {
    fontSize: 14,
    marginTop : 5
  },
  pos: {
    marginBottom: 12
  }
});

function PaymentCard({
  title,
  suffix = "",
  value,
  payment,
  icon
}) {
  const classes = useStyles();
  let paymentValue = <span> &#8377; {value}</span>;
  return (
    <Grid item xs={6} md={4} lg={3}>
      <Card className={classes.root}>
      <CardHeader
        avatar={icon}
        subheader={title}
        title={payment ? paymentValue : value}
        classes={{title : classes.title, subheader : classes.subheader}}
      />
      </Card>
    </Grid>
  )
}

function PaymentCards({data = {}, isLoading}) {

  if(isLoading){
      return (
        <Grid container spacing={4} className='extra-bottom-margin'>
            <Grid item xs={6} md={4} lg={3}>
                <Skeleton variant="rect" />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Skeleton variant="rect" />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Skeleton variant="rect" />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Skeleton variant="rect" />
            </Grid>
        </Grid>
      )
  }

  let {balanceAmount, invoiceNumber, totalAmount, totalPaidAmount, affiliateName} = data.affiliateUserPayment;
  
  return (
    <Grid container spacing={4} className='extra-bottom-margin'>
    <PaymentCard title="Name" value={affiliateName} suffix={''}
        icon = {<PersonIcon/>}
      />

      <PaymentCard
        title="Total Amount"
        value={parseFloat(totalAmount)}
        payment={true}
        icon = {<AccountBalanceIcon/>}
        />
        
      <PaymentCard
        title="Paid Amount"
        value={parseFloat(totalPaidAmount)}
        payment={true}
        icon = {<PaymentIcon/>}
        />

      <PaymentCard
        title="Pending Amount"
        value={parseFloat(balanceAmount)}
        payment={true}
        icon = {<AccountBalanceWalletIcon/>}
        />

      <PaymentCard 
        title="Invoice Number" 
        value={invoiceNumber} 
        suffix={''}
        icon = {<ReceiptIcon/>}
      />
    </Grid>
  )
}

export default PaymentCards
