import React from 'react'
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props}/>;
}

function Toast(props) {
  const {
    toastType = 'error',
    toastMsg = "",
    showToast,
    isEdit
  } = props;

  return (
    <Snackbar
      open={showToast}
      autoHideDuration={3000}
      anchorOrigin={{
      vertical: isEdit ? 'bottom' : 'top',
      horizontal: 'center'
    }}>
      <Alert severity={toastType}>
        {toastMsg}
      </Alert>
    </Snackbar>
  )
}

export default Toast
