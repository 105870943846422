import React from 'react';
import {makeStyles, lighten} from '@material-ui/core/styles';
import {Toolbar} from '@material-ui/core';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import {DebounceInput} from 'react-debounce-input';
import { isMobile } from 'react-device-detect';

import SearchInput from './SearchInput';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  highlight: theme.palette.type === 'light'
    ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85)
    }
    : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark
    },
  title: {
    flex: '1 1 100%'
  }
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected = 0,
    tableTitle,
    handleSearchFilter,
    subTitle,
    hideSearch
  } = props;

  const searchInputChange = (event) => {
    let value = event.target.value;
    // console.log(value, 'search value');
    handleSearchFilter(value);
  }

  return (
    <Toolbar
      className={clsx(classes.root, 'table--top--header responsive-row-search', {
      [classes.highlight]: numSelected > 0
    })}>
      {!isMobile && (<div className="evenly-spaced  col-dir">
        {tableTitle && <h3>{tableTitle}</h3>}
        {subTitle && <h5>{subTitle}</h5>}
      </div>)}
      {hideSearch === false && (<DebounceInput
        minLength={3}
        debounceTimeout={400}
        onChange={searchInputChange}
        className="search-input"
        element={SearchInput}/>)}

        {isMobile && hideSearch && <h5>{tableTitle}</h5>}

    </Toolbar>
  );
};

export default TableToolbar
