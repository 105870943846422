import React from 'react'

function LinkTag(props) {
    return (
        <a href={props.itemLink} target="_blank" rel="noreferrer" className="link-tag">
            {props.children}
        </a>
    )
}

export default LinkTag
