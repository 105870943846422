import React from 'react'
import {Card} from '@material-ui/core'
// import LocalShippingIcon from '@material-ui/icons/LocalShipping';

function Cards(props) {
  let {value, subtitle, Icon, type} = props;

  return (
    <Card className="card-box">
      <div className="card-main-content">
        <div 
        // className={`card--icon card-${index}`}
        className="card--icon"
        >
          <Icon/>
        </div>
        <div className="card--content">
          {type && type === 'PAYMENT'
            ? (
              <h4 className="card--title">
                &#8377;{value}
              </h4>
            )
            : (
              <h4 className="card--title">
                {value}
              </h4>
            )}

          <p className="card--subtitle">
            {subtitle}
          </p>
        </div>
      </div>
      {/* <Divider/>
      <Button className="card-subbody">
        <ViewCompactIcon/>
        <span>View more</span>
      </Button> */}
      {/* <p className="card-subbody">
        <ViewCompactIcon/>
        <span>View more</span>
      </p> */}
    </Card>
  )
}

export default Cards
