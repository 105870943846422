import React, {useReducer, useContext, useLayoutEffect, useState} from 'react'
import {TextField, InputAdornment, makeStyles, Button, Grid, CircularProgress} from '@material-ui/core'
import CardContentBox from '../Cards/CardContentBox'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SendIcon from '@material-ui/icons/Send';
import {mobileNumberValidation, otpValidation, isNotNullOrEmpty} from '../../utils/validations';
import {URLS} from '../../utils/routes';
import useAxios from 'axios-hooks'
import { useLocalStorageState } from '../../hooks/useLocalStorageHook';
import Toast from '../Toast/Toast';
import { AuthContext } from '../../context/Auth';
import { expiryTime } from '../../utils/constants';
import { displayToast } from '../../utils/displayToast';
import Toastify from '../Toast/Toastify';
import { BASE_WEBSITE_PATH, PRODUCTION, CORS_PATH } from '../../utils/baseRoutes';
import { fetchApi } from '../../utils/hitApi';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'column'
  }
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'inherit',
    textAlign: 'center',
    marginTop: '25px'
  }
}));

const initialState = {
  mobileNo: "",
  mobileNoError: false,
  currentStage: 'FIRST',
  otp: "",
  otpError: false,
  showToast : false,
  toastType : "",
  toastMsg : "",
  showMainLoader : true
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MOBILE_NUMBER':
      return {
        ...state,
        mobileNo: action.mobileNo ?? state.mobileNo,
        mobileNoError: action.mobileNoError
      }

    case 'SET_OTP':
      return {
        ...state,
        otp: action.otp ?? state.otp,
        otpError: action.otpError
      }

    case 'SET_SECOND_STAGE':
      return {
        ...state,
        currentStage: 'SECOND',
        showToast : action.showToast,
        toastType : action.toastType,
        toastMsg : action.toastMsg
      }

    case 'SET_FIRST_STAGE':
      return {
        ...state,
        currentStage: 'FIRST'
      }

      case 'SET_TOAST':
        return {
          ...state,
          showToast : action.showToast,
          toastType : action.toastType,
          toastMsg : action.toastMsg
        }

      case 'RESET_FORM':
          return initialState;

      case 'MAIN_LOADER':
            return {...state, showMainLoader : action.showMainLoader}
    default:
      return state;
  }
}

function Form({userData : userProfileData, setUserData : setUserProfileData, isEdit = false}) {

  const [brandName, setBrand] = useLocalStorageState('brand', "");
  // console.log({brandName});

  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    let isActive = true;
    if(!isEdit){
        const pageUrl = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);

        const brand = urlParams.get('brand');
        const token = urlParams.get('token');

      if(isActive){
          dispatch({type : 'MAIN_LOADER', showMainLoader : !!token});
       
          if(brand){
            setBrand(brand.toUpperCase().trim());
          }else{
            if(pageUrl.includes('insightcosmetics.in')){
              setBrand('INSIGHT');  
            }else if(pageUrl.includes('seekcaus.com')){
              setBrand('SEEKCAUS');  
            }else{
              setBrand('EMERCE');
            }
          }

          if(token){
            getOtpForUser(true,token,false,brand);
          }
       }
      }

    return () => {
      isActive = false;
    }
  }, [isEdit]);

  const {setUserData} = useContext(AuthContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const {mobileNo, mobileNoError, otp, otpError, currentStage, showToast, toastType, toastMsg, showMainLoader} = state;

  const classes = useStyles();

  const buttonClasses = useButtonStyles();

  const displayToast1 = ({showToast, toastType, toastMsg}) =>{
    dispatch({
      type : 'SET_TOAST', 
      showToast, 
      toastType, 
      toastMsg
    });

    setTimeout(() => {
      dispatch({
        type : 'SET_TOAST', 
        showToast : false, 
        toastType : 'info', 
        toastMsg : ''
      });
    }, 3000);
  }

  const checkMobileNo = (event) => {
    let val = event.target.value;
    let isValidNumber = mobileNumberValidation(val);
    if(val.length <= 10){
      dispatch({
        type: 'SET_MOBILE_NUMBER',
        mobileNo: val,
        mobileNoError: !isValidNumber
      });
  }
    // if (isNotNullOrEmpty(val)) {
    //   dispatch({
    //     type: 'SET_MOBILE_NUMBER',
    //     mobileNo: val,
    //     mobileNoError: !isValidNumber
    //   });
    // }else{

    // }
  }

  const checkOtp = (event) => {
    let val = event.target.value;
    let isValidOtp = otpValidation(val);
    if(val.length <= 6){
      dispatch({
        type: 'SET_OTP',
        otp: val,
        otpError: !isValidOtp
      });
  }
    // if (isNotNullOrEmpty(val)) {
    //   dispatch({
    //     type: 'SET_OTP',
    //     otp: val,
    //     otpError: !isValidOtp
    //   });
    // }
  }

  const getUrl = (url) =>{
    const BASE_URL = BASE_WEBSITE_PATH[brandName].BACKEND;
    // return  `api/${brandName}/${url}`;
    return `${CORS_PATH}${BASE_URL}${url}`;
    if(PRODUCTION){
        return  `api/${brandName}/${url}`;
    }else{
        return  url;
    }
  }

  const submitData = async (body, url,isResendCase) =>{
    
    // const url = isEdit ? getUrl(URLS.GET_OTP_TO_UPDATE_MOBILE_NO) : getUrl(URLS.GET_OTP_TO_LOGIN);
    
    try {
      
    const res = await fetchApi({
      url,
      body,
      method : 'POST',
      headers : {
        "Access-Control-Allow-Origin" : "*",
      "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin, X-Requested-With, Content-Type, Accept,Authorization"
      }
    });

    setLoading(false);
    
    if(isResendCase){
      if(res){
        res.isResendCase = true;
      }
    }
    saveApiData(res);
    } catch (error) {
        console.log(error);
    }
  }

  const getOtpForUser = (isAdmin, token,isResendCase,brandName) =>{
    const url = isEdit ? URLS.GET_OTP_TO_UPDATE_MOBILE_NO : URLS.GET_OTP_TO_LOGIN;
    setLoading(true);
    const body = { 
      mobileNo,
      isAdmin,
      token,
      affiliateUserId : isEdit ? userProfileData.id : -1,
      brandName
    };
    
    submitData(body, url,isResendCase);
  }

  const verifyOtpForUser = () => {
    const url = isEdit ? URLS.VERIFY_MOBILE_NO_TO_UPDATE : URLS.VERIFY_OTP_TO_LOGIN;
    setLoading(true);
    const body = {
      mobileNo,
      otp,
      affiliateUserId : isEdit ? userProfileData.id : -1,
      brandName
    }
    
    submitData(body, url);
  }

  // const [
  //   {
  //     data,
  //     loading,
  //     error
  //   },
  //   sendOtpToUser] = useAxios({
  //   url: isEdit ? getUrl(URLS.GET_OTP_TO_UPDATE_MOBILE_NO) : getUrl(URLS.VERIFY_OTP_TO_LOGIN),
  //   method: 'POST',
  //   headers : {
  //     "Access-Control-Allow-Origin" : "*",
  //     "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin, X-Requested-With, Content-Type, Accept,Authorization"
  //   }
  // }, {manual: true});

  // const getOtpForUser = async(isAdmin, token) => {
  //   const res = await sendOtpToUser({data: {
        // mobileNo,
        // isAdmin,
        // token,
        // affiliateUserId : isEdit ? userProfileData.id : -1
  //     }});
  //   // console.log({res});
  //   saveApiData(res);
  // }

  // const [
  //   {
  //     data : userData,
  //     loading : otpLoading,
  //     error : otpApiError
  //   },
  //   verifyUser] = useAxios({
  //   url: isEdit ? getUrl(URLS.VERIFY_MOBILE_NO_TO_UPDATE) : getUrl(URLS.VERIFY_OTP_TO_LOGIN),
  //   method: 'POST'
  // }, {manual: true})

  // const verifyOtpForUser = async() => {
  //   const res = await verifyUser({
  //     data: {
        // mobileNo,
        // otp,
        // affiliateUserId : isEdit ? userProfileData.id : -1
  //     }
  //   });
  //   saveApiData(res);
  // }

  const hitLoginRequest = () => {
    if (currentStage === 'FIRST') {
      if(isEdit){
        if(mobileNo === userProfileData.mobileNo){
          displayToast({
            showToast : true, 
            toastType : 'error', 
            toastMsg : 'Please enter new mobile number'
          });
          return false;
        }
      }
      if (!mobileNoError && mobileNumberValidation(mobileNo)) {
        getOtpForUser();
      } else {

        displayToast({
          showToast : true, 
          toastType : 'error', 
          toastMsg : 'Please enter valid mobile number'
        });
        
      }
    } else {
      if (!otpError && otpValidation(otp)) {
        verifyOtpForUser();
      } else {
        displayToast({
          showToast : true, 
          toastType : 'error', 
          toastMsg : 'Please enter valid otp'
        });
      }
    }
  }

  const saveApiData = (data) => {
    // console.log(data);
    let {success, msg} = data;
    if (success) {
      // let setupTime = new Date().getTime();

      let {isAdmin} = data;

      if(isAdmin){

        setUserData({
          isAdmin : true,
          id : 1,
          // setupTime
        });

      }else if (currentStage === 'FIRST') {

         dispatch({
           type: 'SET_SECOND_STAGE'
        });

        displayToast({
          showToast : true, 
          toastType : 'success', 
          toastMsg : msg
        });

      } else if(currentStage === 'SECOND'){
        let {affiliateUser, isResendCase = false} = data;
        if(!isResendCase){
            // expiry session
            // let startedIn = dayjs().millisecond()
              // let setupTime = new Date().getTime() + expiryTime;
            // let expiresIn = dayjs().add(1, 'year').get('millisecond');
              // affiliateUser.setupTime = setupTime;

            if(isEdit){
                setUserProfileData({
                  ...userProfileData,
                  mobileNo
                });
                // window.location.reload();
                setTimeout(() => {
                  window.location.reload();
                }, 100);
                
            }else{
              setUserData(affiliateUser);
            }
            dispatch({type : 'RESET_FORM'});
            displayToast({
              showToast : true, 
              toastType : 'success', 
              toastMsg : msg
            });
        }else{
          displayToast({
            showToast : true, 
            toastType : 'success', 
            toastMsg : msg
          });
        }
      }
    }else{
      displayToast({
        showToast : true, 
        toastType : 'error', 
        toastMsg : msg
      });
    }
  }

  const tryAnotherNumber = () =>{
    if(!loading){
      dispatch({type : 'SET_FIRST_STAGE'});
    }
  }

  const resendOtp = async() =>{
    if(!loading){
      if (!mobileNoError && mobileNumberValidation(mobileNo)) {
          getOtpForUser(null,null, true);
          // const res = await sendOtpToUser({
          //   body: {
          //   mobileNo
          // }});

          // let {data} = res;
          // let {success, msg} = data;

          // displayToast({
          //   showToast : true, 
          //   toastType : success ? 'success': 'error', 
          //   toastMsg : msg
          // });
      }
    }
  }

  const formSubmit = (e) =>{
    e.preventDefault();
    // let {charCode, which, keyCode} = e;
    // if(keyCode === 13 || which === 13 || charCode === 13){
      hitLoginRequest();
    // }
  }

  React.useEffect(() => {
    let isActive = true;
    if(isActive && isEdit){
      dispatch({type : 'SET_MOBILE_NUMBER', mobileNo : userProfileData.mobileNo, mobileNoError : false});
      dispatch({type : 'MAIN_LOADER', showMainLoader : false});
    }
    return () => {
      isActive = false;
    }
  }, [userProfileData, isEdit]);

  return (
    <>
      <CardContentBox className={showMainLoader ? 'center-align' : ''}>
      {
        showMainLoader ? (
          <CircularProgress />
        ) : (
        <form className={classes.root} onSubmit={formSubmit}>

          {currentStage === 'FIRST'
            ? (
              <TextField
                id="mobile-number"
                label="Mobile Number"
                variant="outlined"
                color="primary"
                value={mobileNo}
                onChange={checkMobileNo}
                // onKeyPress={formSubmit}
                error={mobileNoError}
                type="number"
                helperText={mobileNoError ? "Enter 10 digit mobile number" : ""}
                required
                InputProps={{
                startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneAndroidIcon/>
                  </InputAdornment>
                )
              }}/>
            )
            : (
              <TextField
                id="otp"
                label="Enter OTP"
                variant="outlined"
                color="primary"
                value={otp}
                onChange={checkOtp}
                error={otpError}
                type="number"
                helperText={otpError ? "Enter 6 digit otp. Only numbers allowed" : ""}
                required
                InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BusinessCenterIcon/>
                  </InputAdornment>
                )
              }}/>
            )}
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            className={buttonClasses.root}
            onClick={hitLoginRequest}
            disabled={loading}>
            {currentStage === 'FIRST'
              ? 'Request OTP'
              : isEdit ? 'Change Number' : 'Login'}
              {(loading) && (<CircularProgress />)}
          </Button>

          {(isEdit === false && currentStage === 'SECOND') && (
            <Grid container spacing={1} justify="center" alignItems="center">

              <Grid item xs={6} className="center-align">
                <Button color="primary" className={buttonClasses.root} onClick={tryAnotherNumber}
                disabled={loading}
                >
                  Try another number?
                </Button>
              </Grid>

              <Grid item xs={6} className="center-align">
                <Button color="primary" className={buttonClasses.root} onClick={resendOtp}
                disabled={loading}
                >
                  Resend OTP
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
        )
      }
      </CardContentBox>
      <Toast showToast={showToast} toastMsg={toastMsg} toastType={toastType} isEdit={isEdit}/>
      {/* {(otpApiError) && (<Toast showToast={true} toastMsg={otpApiError.message} toastType={'error'} isEdit={isEdit}/>)} */}
        <Toastify/>
    </>
  )
}

export default Form
