import React from 'react'
import {CopyLinkTableColumn} from '../components/LinkComponents/CopyLinkTableColumn';
import {
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableFooter,
  Typography,
  Toolbar,
  Tooltip
} from '@material-ui/core';
import {makeStyles, lighten} from '@material-ui/core/styles';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const ImageComponent = {
  // Make an expander cell
  Header: () => 'Product Image',
  id: 'productImage', // It needs an ID
  Cell: ({row}) => {
    let {original} = row;
    let {productImage} = original;
    return (<img src={productImage} alt="abc"/>)
  }
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight: theme.palette.type === 'light'
    ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85)
    }
    : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark
    },
  title: {
    flex: '1 1 100%'
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {numSelected = 0, tableTitle} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
      [classes.highlight]: numSelected > 0
    })}>
      <h3>{tableTitle}</h3>
    </Toolbar>
  );
};

function ManageTable() {

  const columns = React.useMemo(() => [
    {
      Header: 'Sr. No',
      accessor: 'srNo'
    },
    ImageComponent, {
      Header: 'Product Name',
      accessor: 'name'
    },
    CopyLinkTableColumn
  ], []);

  return (
    <div>
      <TableComponent columns={columns} data={[]} tableTitle={'Manage Tables'}/>
    </div>
  )
}

function TableComponent({data, columns, tableTitle}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar tableTitle={tableTitle}/>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="table" stickyHeader={true}>
            <TableHead>
              <TableRow>
                  {columns.map(item =>{
                      return (
                          <TableCell key={item.Header}>
                            {item.Header}
                          </TableCell>
                      )
                  })}
              </TableRow>
            </TableHead>

            <TableBody>
                {data.map((item,index) =>{
                    let {id} = item;
                    if(!id){
                        id = uuidv4();
                    }
                    return(
                       <TableRow key={id}>

                       </TableRow> 
                    )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

function prepareRow(row){

}
export default ManageTable
