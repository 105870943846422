import React from 'react'
import {usePaginatedQuery} from 'react-query';
import DatePicker from '../components/DatePicker'
import MultipleSelect from '../components/MultiSelect/MultipleSelect';
import {Grid, Button} from '@material-ui/core';
import { ALL_OPTIONS, INITIAL_DATE_SETUP, LONG_CACHE, PAGE_SIZE } from '../utils/constants';
import { AuthContext } from '../context/Auth';
import UserCard from '../components/Tables/UserCard';
import { SerialNumberHeader } from '../components/Tables/SerialNumberHeader';
import Toastify from '../components/Toast/Toastify';
import { getDates, getServerDate, getWebsiteDate } from '../utils/functions';
import TableComponent from '../components/Tables/TableComponent';
import { URLS } from '../utils/routes';
import { fetchApi } from '../utils/hitApi';
import CustomSelect from '../components/Select/CustomSelect';
import { Link } from 'react-router-dom';
import {NumberCell} from '../components/Tables/NumberCell';
import StatusChips from '../components/Tables/StatusChips';
import PaymentCard from '../components/Tables/PaymentCard';
import { useRouterQuery } from '../hooks/useRouterQuery';
// import { useParams} from "react-router-dom";
let pageSize = PAGE_SIZE;

const PAYMENT_OPTIONS = [
  {
    id : 1,
    itemValue : 'ALL',
    title : 'All'
  },
  {
    id : 2,
    itemValue : 'PAID',
    title : 'Paid'
  },
  {
    id : 3,
    itemValue : 'UNPAID',
    title : 'Unpaid'
  },
  {
    id : 4,
    itemValue : 'PARTIALPAID',
    title : 'Partially Paid'
  },
];

const listName = "affiliateUserInvoiceList";

function ManagePayment() {
  const pageData = React.useRef([]);
  // let { id } = useParams();
  let query = useRouterQuery();
  const id = query.get('id');
  const name = query.get('name');

  const {isAdmin, userData} = React.useContext(AuthContext);
  const [dates, setDates] = React.useState(INITIAL_DATE_SETUP);
  const [page, setPage] = React.useState(1);
  const [paymentStatus, setPaymentStatus] = React.useState('ALL');

  const [affiliateUsers, setAffiliateUsers] = React.useState(id ? [{id}]: [ALL_OPTIONS]);
  // const [affiliateUsers, setAffiliateUsers] = React.useState(id ? [{id, name : name.split("-").join(" ")}] : [ALL_OPTIONS]);

  // const [affiliateUsersList, setAffiliateUsersList] = React.useState(()=> isAdmin ? [] : [userData.id]);
  const [affiliateUsersList, setAffiliateUsersList] = React.useState(isAdmin ? id ? [id] : [] : [userData.id]);

  const [allAffiliate, setAllAffiliate] = React.useState(!isAdmin ? false : id ? false : true);

  const changeDates = React.useCallback(
    (data) => {
      setDates(data)
    },
    [],
  )

  const changeAffiliates = React.useCallback(
    (data) => {
      setAffiliateUsers(data);
      let isAllSelected = false;
      let list = data.map(item => {
        if(item.id === -1){
          isAllSelected = true;
        }
        return item.id
      });
      setAffiliateUsersList(list);
      setAllAffiliate(isAllSelected);
    },
    [],
  )

  const columnsNew = React.useMemo(() => [
    SerialNumberHeader,
    {
      Header: 'Invoice No.',
      accessor: 'invoiceNumber'
    },
    {
      Header: 'Name',
      id : 'name',
      Cell: ({row}) => {
        let {original} = row;
          return <UserCard {...original}/>
      }
    }, 
    {
      Header: 'Amount',
      accessor: 'totalAmount',
      ...NumberCell
    },
    {
      Header: 'Paid', 
      id: 'paidAmount',
      Cell: ({row}) => {
        let {original} = row;
        let {affiliateUserInvoiceId,paidAmount} = original;
          return <PaymentCard totalAmount={paidAmount} affiliateUserInvoiceId={affiliateUserInvoiceId}/>
      }
    },
    {
      Header: 'Unpaid', 
      accessor: 'balanceAmount',
      ...NumberCell
    },
    {
      Header: 'Staus', 
      accessor: 'payStatus',
      Cell: ({
        cell: {
            value
        }
    }) => (
      <StatusChips value={value}/>
    )
    },
    {
        Header: 'Invoice Date', 
        accessor: 'invoiceDate',
        Cell: ({
          cell: {
              value
          }
      }) => (
        <span>{getWebsiteDate(value)}</span>
      )
    }
    ,{
      Header: () => 'Action',
      id: 'action', // It needs an ID
      Cell: ({row}) => { 
        let {original} = row;
        let {affiliateUserInvoiceId, payStatus, totalAmount} = original;
        if(payStatus && payStatus.toUpperCase() === 'PAID'){
          return (
            <PaymentCard totalAmount={totalAmount} label="View history" affiliateUserInvoiceId={affiliateUserInvoiceId}/>
          )
        }else if(!isAdmin){
          return <span>-</span>
        }else{
          return (
                <Link to={`add-edit-payment?id=${affiliateUserInvoiceId}&status=INSERT`}>
                  <Button size="small" 
                      variant="contained" 
                      color="primary">
                        Pay
                  </Button>
                </Link>
          )
        }
      }
    }
  ], []);

  const increasePageCount = React.useCallback(
    (pageNo,newPageSize) => {
      // if(pageNo > 1){
        // console.log('setting : ', {pageNo, newPageSize});
        setPage(pageNo);
        pageSize = newPageSize;
      // }
    },
    [],
  );

  const fetchPaymentList = async(key, pageNo = 1, pageSize, startDate, endDate, paymentStatus, affiliateUsersList) =>{
    if(!!startDate && !!pageNo && !!pageSize && !!endDate && !!paymentStatus){
      let sDate = getServerDate(startDate);
      let eDate = getServerDate(endDate);

      const res = await fetchApi({
        url : URLS.FETCH_PAYMENT_LIST,

        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: {
          "pageNo": pageNo, 
          "pageSize": pageSize, 
          paymentStatusFilter : paymentStatus, 
          startDate : sDate, 
          endDate : eDate,
          affiliateUserIds : affiliateUsersList,
          allAffiliate
      },

        // Adding headers to the request
        // headers: {
        //   "Content-type": "application/json; charset=UTF-8"
        // }
      });
      // const result = await res.json();
      return res;
   }else{
     return {};
   }
  }

  const {
    isLoading,
    isError,
    error,
    resolvedData,
    latestData,
    isFetching
  } = usePaginatedQuery([
    'paymentList', page, pageSize, 
    dates[0].startDate,
    dates[0].endDate,
    paymentStatus,
    affiliateUsersList
  ], fetchPaymentList, {
    // ...LONG_CACHE,
    retry: 1,
    refetchOnWindowFocus: true
  });

  if(resolvedData && Object.keys(resolvedData).length > 0){
    pageData.current[page] = resolvedData[listName];
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>

      {isAdmin && (
        <Grid item xs={12} md={4}>
          <MultipleSelect value={affiliateUsers}  changeHandler={changeAffiliates} customSelect={true}/>
        </Grid>
      )}

      <Grid item xs={12} md={4}>
        <CustomSelect handleChange={setPaymentStatus} 
        value = {paymentStatus}
         label = {'Select payment status'}
          options = {PAYMENT_OPTIONS}
          defaultValue ={'ALL'} />
      </Grid>

        <Grid item xs={12} md={isAdmin ? 4 : 8}>
          <DatePicker dates={dates} changeDates={changeDates}/>
        </Grid>
      </Grid>

      <TableComponent 
        columns={columnsNew} 
        // data={isError ? [] : resolvedData}
        data={resolvedData ? {[listName] : pageData.current[page], totalRecords : resolvedData.totalRecords} : []}
        fetchData={increasePageCount}
        loading={isLoading}
        changeKey = {paymentStatus}
        initialPage = {1}
        currentPageNumber = {page}
        manualSearch = {true}
        manualPagination = {true}        
        modifyTableData = {modifyTableData}
        subTitle = {`Manage Payment`}
        tableTitle = {getDates(dates)}
        listName = {listName}
        currentPageSize = {pageSize}
        isFetching = {isFetching}
        changeKeyOne = {affiliateUsers}
        hideSearch={true}
        />

        <Toastify/>
    </React.Fragment>
  )
}

function modifyTableData(list){
  return list;
}

export default ManagePayment
