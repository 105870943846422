import React, {useContext, useRef} from 'react'
import {useQuery} from 'react-query';
// import {BASE_WEBSITE_PATH, URLS} from '../utils/routes';

import { LONG_CACHE } from '../utils/constants';

import { useTable, } from 'react-table'
import { AuthContext } from '../context/Auth';
import { makeStyles } from '@material-ui/core/styles';

import {TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TableFooter} from '@material-ui/core';

import TablePaginationActions from '../components/Tables/TablePaginationActions';

import {CopyLinkTableColumn} from '../components/LinkComponents/CopyLinkTableColumn';
import {OpenLinkTableColumn} from '../components/LinkComponents/OpenLinkTableColumn';

import { useBrand } from '../hooks/useBrand';
import { SerialNumberHeader } from '../components/Tables/SerialNumberHeader';
// import TableComponent from '../components/Tables/TableComponent';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableToolbar from '../components/Tables/TableToolbar';
import { BASE_WEBSITE_PATH } from '../utils/baseRoutes';
import { URLS } from '../utils/routes';
import { fetchApi } from '../utils/hitApi';
import { getLocalStorageKey } from '../hooks/useLocalStorageHook';
const url = URLS.FETCH_CATEGORIES;

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

function GetCategoryLink() {

    const {userData} = useContext(AuthContext);
    const [brandName] = useBrand();

    const columns = React.useMemo(
        () => [
          // SerialNumberHeader,
          {
            Header: 'Sr. No',
            accessor: 'srNo',
          },
          {
            Header: 'Category Name',
            accessor: 'name',
          },
          // {
          //   Header: 'Category Link',
          //   accessor: 'itemLink',
          // },
          OpenLinkTableColumn,
          CopyLinkTableColumn,
    ],
  []);

  const fetchCategories = async() => {
    const res = await fetchApi({
      url,

      // Adding method type
      method: "GET",
    });
    // const result = await res.json();
    return res;
    // const res = await fetch(url);
    // const result = await res.json();
    // return result;
    // setTableData(res);
    // return res;
  }

   const {isLoading, error, data, status} = useQuery('categoryLink', fetchCategories, {
    ...LONG_CACHE
  });
  if(status === 'success'){
    return(
      <TableComponent columns={columns} 
        data={data}
        // data = {[]}
        loading={isLoading}
        // pageCount={1000}
        brandName={brandName}
        userData={userData}
        initialPage = {1}
        currentPageNumber = {1}
        manualSearch = {false}
        manualPagination = {false}
        modifyTableData = {modifyTableData}
        subTitle="Click on icon to copy link"
        tableTitle = 'Get shareable category link'
      />)
  }else{
    return null;
  }
}

function modifyTableData(list, userData, brandName){

  if(!brandName){
    brandName = getLocalStorageKey('brand');
 }
    const frontEndUrl = BASE_WEBSITE_PATH[brandName].FRONTEND;

    let {userCode} = userData;

    userCode = userCode.replace(" ", "-");

    let tableData = list.map((item, index) =>{
        let {id, name, title} = item;
        let itemLink = `${frontEndUrl}${title}/c?id=${id}&tag=${userCode}`;
        return ({
            srNo : index + 1,
            name,
            itemLink
        });
    });
    return tableData;
}

function TableComponent({
    columns,
    data : tableData,
    loading,
    userData,
    brandName,
    tableTitle,
    subTitle
 }){
   
    const classes = useStyles();
    let {categoriesList} = tableData;
    let count = categoriesList.totalRecords;
    let data = [];

    if(categoriesList && categoriesList.length > 0){
      data = modifyTableData(categoriesList, userData, brandName);
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ 
        columns, 
        data, 
      });
    
      const [page, setPage] = React.useState(0);
      const [rowsPerPage, setRowsPerPage] = React.useState(5);

      const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      return (
        <React.Fragment>
        {loading && <LinearProgress />}
      
        <Paper className={classes.root}>
        <TableToolbar tableTitle={tableTitle} subTitle={subTitle} hideSearch={true}/>
        <TableContainer className={classes.container}>
        <Table aria-label="simple table" {...getTableProps()} stickyHeader={true}>
        <TableHead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                
                    {// Loop over the headers in each row
                    headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <TableCell align="left" {...column.getHeaderProps()}>
                        {// Render the header
                        column.render('Header')}
                    </TableCell>
                    ))}
                </TableRow>      
            ))}
          </TableHead>
          
          <TableBody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <TableRow {...row.getRowProps()}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <TableCell align="left" {...cell.getCellProps()}>
                        {// Render the cell contents
                        cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        {/* <TablePagination count={count} page={0} rowsPerPage={10} rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}  /> */}
        </Table>
        </TableContainer>
        </Paper>
        </React.Fragment>
      )
}

export default GetCategoryLink
