import React from 'react'
// import {useParams} from "react-router-dom";
import PaymentMethods from '../components/PaymentMethods';
import PaymentForm from '../components/PaymentForm';
import {useRouterQuery} from '../hooks/useRouterQuery';
import {URLS} from '../utils/routes';
import {useQuery} from 'react-query'
import {fetchApi} from '../utils/hitApi';
import PaymentCards from '../components/PaymentCards';
import Toastify from '../components/Toast/Toastify';

function AddEditPayment() {
  let query = useRouterQuery();
  const id = query.get('id');
  const status = query.get('status');

  const fetchPaymentDetails = async() => {
    const res = await fetchApi({
      url: status === 'INSERT' ? URLS.FETCH_PAYMENT_DETAILS : URLS.FETCH_PAYMENT_DETAIL_TO_UPDATE,
      // Adding method type
      method: "POST",
      body: {
        affiliateUserInvoiceId: id,
        paymentId : id
      }
    });
    return res;
  }

  const {isLoading, error, data} = useQuery([
    'paymentDetails', id
  ], fetchPaymentDetails, {
    // ...LONG_CACHE,
    refetchOnWindowFocus: true,
    retry: 1
  });

  // console.log({data}, 'paymentData in AddEditPayment');

  return (
    <React.Fragment>
      <PaymentCards data={data} isLoading={isLoading}/>
      <PaymentMethods 
      status={status}
      // paymentData={status === 'INSERT' ? {} : data} 
      paymentData={data} 
      affiliateUserInvoiceId={id}/>
      <Toastify/>
    </React.Fragment>
  )
}

export default AddEditPayment
