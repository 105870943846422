import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {isMobile} from "react-device-detect";

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
 
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {id: `scrollable-auto-tab-${index}`, 'aria-controls': `scrollable-auto-tabpanel-${index}`};
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

export default function TabsPanel({
  tabsHeader = [],
  tabsData = []
}) {
  const classes = useStyles();
  const [value,
    setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile
          ? "scrollable"
          : "fullWidth"}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">

          {tabsHeader.map(item => {
            let {id, title} = item;
            return (<Tab key={id} label={title} {...a11yProps(id)}/>)
          })}

        </Tabs>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
  Item One
</TabPanel> */}

      {tabsData.map((item, index) => {
        let {id, title} = item;
        return (
          <TabPanel value={value} index={index} key={id}>
            {title} Item
          </TabPanel>
        )
      })}

    </div>
  );
}
