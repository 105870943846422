import React from 'react'

import {
    makeStyles,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    DialogActions
  } from '@material-ui/core'
  import IconButton from '@material-ui/core/IconButton';
  import CloseIcon from '@material-ui/icons/Close';

  const useStyles = makeStyles(theme => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    modal : {
      minWidth : '70vw'
    }
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props}/>;
  });
  
  function AddressCard({value = "", label}) {
    const classes = useStyles();

    const [open,
      setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    let truncateTxt = value.substring(0,6);
    
    if(value.length > 6){
      truncateTxt += '...';
    }
  
    return (
      <React.Fragment>
        <Button size="small" color="primary" onClick={handleClickOpen}>
         {label ? truncateTxt : 'View'} 
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          // className={classes.modal}
          fullWidth={true}
          >
          <DialogTitle id="alert-dialog-slide-title">{label || "User Address"}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
                {value}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
  
  export default AddressCard
  