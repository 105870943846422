import { Grid } from '@material-ui/core';
import React from 'react'
import { usePaginatedQuery } from 'react-query';
import DatePicker from '../components/DatePicker';
import MultipleSelect from '../components/MultiSelect/MultipleSelect';
import AddressCard from '../components/Tables/AddressCard';
import CouponCard from '../components/Tables/CouponCard';
import { NumberCell } from '../components/Tables/NumberCell';
import { SerialNumberHeader } from '../components/Tables/SerialNumberHeader';
import TableComponent from '../components/Tables/TableComponent';
import UserCard from '../components/Tables/UserCard';
import {AuthContext} from '../context/Auth';
import {ALL_OPTIONS, INITIAL_DATE_SETUP, PAGE_SIZE} from '../utils/constants';
import { getWebsiteDate, getDates, getServerDate  } from '../utils/functions';
import Toastify from '../components/Toast/Toastify';
import { URLS } from '../utils/routes';
import { fetchApi } from '../utils/hitApi';
import { CopyLinkTableColumn } from '../components/LinkComponents/CopyLinkTableColumn';
import SubText from '../components/SubText';

let pageSize = PAGE_SIZE;
// let pageSize = 1;
const listName = "couponCodeList";

function ManageCoupons() {
  const pageData = React.useRef([]);

  const {isAdmin, userData} = React.useContext(AuthContext);

  const [page,
    setPage] = React.useState(1);
  const [dates,
    setDates] = React.useState(INITIAL_DATE_SETUP);

  const [searchedTerm, setSearchdTerm] = React.useState("");

  const [affiliateUsers,
    setAffiliateUsers] = React.useState([ALL_OPTIONS]);

  const [affiliateUsersList,
    setAffiliateUsersList] = React.useState(() => isAdmin
    ? []
    : [userData.id]);

  const [allAffiliate, setAllAffiliate] = React.useState(isAdmin);

  const changeDates = React.useCallback((data) => {
    setDates(data)
  }, [],)

  const changeAffiliates = React.useCallback((data) => {
    setAffiliateUsers(data);
    let isAllSelected = false;
    let list = data.map(item => {
      if (item.id === -1) {
        isAllSelected = true;
      }
      return item.id
    });
    setAffiliateUsersList(list);
    setAllAffiliate(isAllSelected);
  }, []);

  const changeSearchedTerm = React.useCallback(
    (value) => {
      setSearchdTerm(value);
      setPage(1);
    },
    [],
  );

  const fetchCoupons = async(key, pageNo = 1, pageSize, startDate, endDate, searchedTerm, affiliateUsersList) => {
    const affiliates = affiliateUsersList.filter(item => item !== -1);
    if(!!startDate && !!pageNo && !!pageSize && !!endDate){
      // console.log('hit api');
      let sDate = getServerDate(startDate);
      let eDate = getServerDate(endDate);

      const res = await fetchApi({
        url : URLS.FETCH_ALL_COUPONS,

        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: {
          "pageNo": pageNo, 
          "pageSize": pageSize, 
          "searchTerm": searchedTerm, 
          startDate : sDate, 
          endDate : eDate,
          isAdmin,
          // affiliateUserIds : affiliateUsersList,
          affiliateUserIds : affiliates,
          allAffiliate
      },

        // Adding headers to the request
        // headers: {
        //   "Content-type": "application/json; charset=UTF-8"
        // }
      });
      // const result = await res.json();
      return res;
   }else{
     return {};
   }
  }

  const increasePageCount = React.useCallback(
    (pageNo,newPageSize) => {
      // if(pageNo > 1){
        // console.log('setting : ', {pageNo, newPageSize});
        setPage(pageNo);
        pageSize = newPageSize;
      // }
    },
    [],
  );

  const {
    isLoading,
    isError,
    error,
    resolvedData,
    latestData,
    isFetching
  } = usePaginatedQuery([
    'couponList', page, pageSize, 
    dates[0].startDate,
    dates[0].endDate,
    // dayjs(dates[0].startDate).format(SERVER_DATE_FORMAT), 
    // dayjs(dates[0].endDate).format(SERVER_DATE_FORMAT), 
    searchedTerm,
    affiliateUsersList
  ], fetchCoupons, {
    // ...LONG_CACHE,
    retry: 1,
    refetchOnWindowFocus: false
  });

  const columns = React.useMemo(() => [
    SerialNumberHeader,
    {
        Header: 'Coupon',
        id: 'couponCode',
        Cell: ({row}) => {
            let {original} = row;
              return <CouponCard {...original}/>
          }
    },
    {
      Header: 'Applicable on',
      accessor: 'applicationType',
    },
    {
        Header: 'Offer',
        accessor: 'offerHeadLine',
        Cell: ({
          cell: {
              value
          }
      }) => <AddressCard value={value} label="Offer"/>
      }, 
      {
        Header: 'Validity',
        id: 'validity',
        Cell: ({row}) => {
            let {original} = row;
            const {startTime, expiryTime} = original;
              return <span>{getWebsiteDate(startTime)} to {getWebsiteDate(expiryTime)}</span>
          }
      },
      {
        ...CopyLinkTableColumn,
        Header: () => 'Copy Code',
      },
      {
        Header: 'Commission',
        accessor: 'commission',
        canBeFiltered : true,
        ...NumberCell
    }
    ], []);

    const adminColumns = React.useMemo(() => [
        ...columns,
        {
            Header: 'Revenue',
            accessor: 'revenue',
            canBeFiltered : true,
            ...NumberCell
        },
        {
          Header: 'Name',
          id : 'name',
          Cell: ({row}) => {
            let {original} = row;
              return <UserCard {...original}/>
          }
       },
    ], []);
    
    if(resolvedData && Object.keys(resolvedData).length > 0){
      pageData.current[page] = resolvedData[listName];
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>

      {isAdmin && (
        <Grid item xs={12} md={6}>
          <MultipleSelect value={affiliateUsers}  changeHandler={changeAffiliates}/>
        </Grid>
      )}

      <Grid item xs={12} md={isAdmin ? 6 : 12}>
          <DatePicker dates={dates} changeDates={changeDates} showSubTxt={!isAdmin}/>
        </Grid>
      </Grid>

      {isAdmin && (<Grid item xs={12}>
        <SubText/>
      </Grid>)}

      <TableComponent 
        columns={isAdmin ? adminColumns : columns} 
        // columns={columns}
        // data={isError ? [] : resolvedData}
        data={resolvedData ? {[listName] : pageData.current[page], totalRecords : resolvedData.totalRecords} : []}
        fetchData={increasePageCount}
        loading={isLoading}
        changeKey = {null}
        initialPage = {1}
        currentPageNumber = {page}
        manualSearch = {true}
        manualPagination = {true}     
        changeSearchedTerm = {changeSearchedTerm}   
        modifyTableData = {modifyTableData}
        subTitle = {`Manage Coupons`}
        tableTitle = {getDates(dates)}
        listName = {listName}
        currentPageSize = {pageSize}
        isFetching = {isFetching}
        changeKeyOne = {affiliateUsers}
        hideSearch={false}
        />

        <Toastify/>
    </React.Fragment>
  )
}

function modifyTableData(list){
  let newList = list.map(item =>{
    return {
      ...item,
      itemLink : item.couponCode,
      itemLinkLabel : 'Coupon Code'
    }
  });
  return newList;
}

export default ManageCoupons