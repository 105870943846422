import React from 'react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {Button} from '@material-ui/core';
import swal from 'sweetalert';
import { fetchApi } from '../../utils/hitApi';
import { URLS } from '../../utils/routes';
import { displayToast } from '../../utils/displayToast';

function DeletePayment({paymentId, removeList}) {

  const deletePayment = async () => {
    swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this payment!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
            return deletePaymentApi();
        } else {
            swal.stopLoading();
            swal.close();
        }
      }).then(res =>{
        swal.stopLoading();
      });
  }

  const deletePaymentApi = async() =>{
    const res = await fetchApi({
        url : URLS.DELETE_PAYMENT ,
        // Adding method type
        method: "POST",
        // Adding body or contents to send
        body : {
            paymentId
        },
      });

      let {success, msg} = res;
      let toastType = success ? 'success' : 'error';

      if(success){
        // removeList(paymentId);
        window.location.reload();
        // setTimeout(() => {
        // }, 1500);
      }

      displayToast({toastMsg : msg, toastType});
      return res;
  }

  return (
    <Button
      size="small"
      variant="outlined"
      color="secondary"
      onClick={deletePayment}>
      <DeleteForeverIcon/>
    </Button>
  )
}

export default DeletePayment
