import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyTxt from '../CopyTxt';
import {IconButton} from '@material-ui/core';

export const CopyLinkTableColumn = {
  Header: () => 'Copy Link',
  id: 'expander', // It needs an ID
  Cell: ({row}) => {
    let {original} = row;
    let {itemLink, itemLinkLabel = "Link"} = original;
    return (
      <CopyTxt label={itemLinkLabel} value={itemLink}>
        <IconButton color="primary" aria-label="copy url" component="span">
          <FileCopyIcon/>
        </IconButton>
      </CopyTxt>
    )
  }
}