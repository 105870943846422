import React from 'react'
import { URLS } from '../utils/routes';
import {useQuery} from 'react-query';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LONG_CACHE, PAGE_SIZE } from '../utils/constants';
import Products from '../components/LinkComponents/Products';
import { fetchApi } from '../utils/hitApi';

const url = URLS.FETCH_CATEGORIES;

function GetProductLink() {
  const [category, setCategory] = React.useState(null);
  const [categoryItem, setCategoryItem] = React.useState(null);

  const fetchCategories = async() => {
    // const res = await fetch(url);
    // const result = await res.json();
    // return result;
    const res = await fetchApi({
      url,

      // Adding method type
      method: "GET",
    });
    // const result = await res.json();
    return res;
  }

  const {error : categoryError, data : categoryData, status : categoryStatus} = useQuery('categoryLink', fetchCategories, {
    ...LONG_CACHE,
    retry : 1,
    refetchOnWindowFocus : false
  });

  const categoryChangeHandler = (event, value) =>{
    setCategory(value.id);
    setCategoryItem(value);
  }

  React.useEffect(() => {
    let isActive = true;

    if(isActive && categoryData && categoryData.categoriesList){
      let {categoriesList} = categoryData;
      let val = categoriesList[0].id;
      // console.log({val});
      setCategory(val);
      setCategoryItem(categoriesList[0]);

    }
    return () => {
      isActive = false;
    }
  }, [categoryData]);

  return (
    <div>
      <Autocomplete
      id="combo-box-demo"
      options={categoryStatus === 'success' ? categoryData.categoriesList : []}
      getOptionLabel={(option) => option.name}
      // defaultValue={categoryData && categoryData.categoriesList && categoryData.categoriesList[0]}
      style={{ width: 300 }}
      value={categoryItem}
      disableClearable
      onChange={categoryChangeHandler}
      className="cards--box"
      renderInput={(params) => <TextField {...params} label="Choose Category" variant="outlined" />}
    />

    <Products category={category}/>
    </div>
  )
}

export default GetProductLink
