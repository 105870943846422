import React from 'react';
import { useLocalStorageState } from '../hooks/useLocalStorageHook';

export const AuthContext = React.createContext();

AuthContext.displayName = 'AuthContext';

export const AuthProvider = (props) => {
  const [userData, setUserData] = useLocalStorageState('userData', null);

  let isLoggedIn = false, isAdmin = false;
  if(userData && userData.id){
    isLoggedIn = true;
    isAdmin = userData.isAdmin || false;
  }
  return (<AuthContext.Provider value={{userData, setUserData, isLoggedIn, isAdmin}} {...props}/>)
}