import React, {
    useRef,
    useState
} from 'react';

import {
    getLocalStorageKey
} from '../hooks/useLocalStorageHook';

export function useBrand(initialState = null) {
    const [state, setstate] = useState(initialState);
    // const brandName = useRef("");

    React.useEffect(() => {
        let isActive = true;
        if (isActive) {
            let brandName = getLocalStorageKey('brand');
            setstate(brandName)
            // brandName.current = getLocalStorageKey('brand');
        }
        return () => {
            isActive = false;
        }
    }, []);
    return [state];
}