import dayjs from "dayjs";
import { SERVER_DATE_FORMAT, WEBSITE_DATE_FORMAT } from "./constants";

export const getWebsiteDate = (date) => dayjs(date).format(WEBSITE_DATE_FORMAT);

export const getServerDate = (date) => dayjs(date).format(SERVER_DATE_FORMAT);


export function getDates(dates){
    if(!dates || dates.length === 0){
      return '-';
    }
    let startDate = dayjs(dates[0].startDate).format(WEBSITE_DATE_FORMAT);
    let endDate = dayjs(dates[0].endDate).format(WEBSITE_DATE_FORMAT);
    return `${startDate} to ${endDate}`;
  }

  export function debounce(func, wait, immediate) {
    var timeout;
  
    return function executedFunction() {
      var context = this;
      var args = arguments;
        
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
  
      var callNow = immediate && !timeout;
    
      clearTimeout(timeout);
  
      timeout = setTimeout(later, wait);
    
      if (callNow) func.apply(context, args);
    };
  };

  export const makeValidLink = (link) =>{
    let tempLink = link;
    if(!/http(s)*:\/\//.test(tempLink)){
      tempLink = `https://${tempLink}`;
    }
    return tempLink;
  }