import {CardHeader, makeStyles} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '-50px',
    marginBottom: '20px',
    borderRadius: '3px',
    textAlign: 'center',
    color: theme.palette.common.white,
    marginRight: 'auto'
  },
  fullWidth : {
    width: '60%',
    marginLeft: 'auto',
    padding: '15px',
  },
  halfWidth : {
    width: '25%',
    height : '25%',
    marginLeft: '20px',
    padding: '20px',
  },
  centerHalfWidth : {
    width: '40%',
    height : '25%',
    marginLeft: 'auto !important',
    padding: '15px',
  }
}));

function CardHeaderBox({fullWidth = false, title, subheader, centerHalfWidth}) {
  const classes = useStyles();
  return (<CardHeader title={title} subheader={subheader} 
   className={clsx(classes.root, {
        [classes.fullWidth]: fullWidth,
        [classes.halfWidth]: !fullWidth,
        [classes.centerHalfWidth] : centerHalfWidth
       })}
  />)
}

export default CardHeaderBox
