import {
    Card,
    CardContent,
    makeStyles,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    DialogActions
  } from '@material-ui/core'
  import React, {useState} from 'react'

  import { getWebsiteDate } from '../../utils/functions';
  
  import {useMutation} from 'react-query';
    import { URLS } from '../../utils/routes';
    import { fetchApi } from '../../utils/hitApi';
    import { Skeleton } from '@material-ui/lab';
import PaymentCards from '../PaymentCards';
import PaymentTable from './PaymentTable';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props}/>;
  });
  
  const useStyles = makeStyles(theme => ({
    root: {
      minWidth: 275
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)'
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
  
  function PaymentCard({affiliateUserInvoiceId, totalAmount, label}) {
    const classes = useStyles();
 
    const [open, setOpen] = React.useState(false);
    // const [data, setData] = React.useState(false);

    const fetchPaymentList = async({affiliateUserInvoiceId}) =>{
        const url = URLS.FETCH_PAYMENTS_BY_INVOICE;
        const res = await fetchApi({
          url ,
          // Adding method type
          method: "POST",
          // Adding body or contents to send
          body : {
            affiliateUserInvoiceId
          },
        });
        return res;
    }

    const [mutate, { isLoading, isError, isSuccess, status, data, error, reset }] = useMutation(fetchPaymentList);

    const fetchList = async() =>{
        await mutate({affiliateUserInvoiceId});
    }

    const handleClickOpen = () => {
        setOpen(true);
        fetchList();
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    //   console.log({ isLoading, isError, isSuccess, status, data, error, reset });
      if(totalAmount <= 0){
          return <span>&#8377; {totalAmount} </span>
      }else{
        return (
          <React.Fragment>
            <Button variant={label ? 'contained' : "outlined"} color="primary" onClick={handleClickOpen}>
              {label || <span>&#8377; {totalAmount} </span>}
            </Button>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              fullWidth
              maxWidth='lg'
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description">
              <DialogTitle id="alert-dialog-slide-title">Affiliate User Details
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              </DialogTitle>
              <DialogContent>
              {isLoading ? (
                  <React.Fragment>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                  </React.Fragment>
              ) : (isSuccess && data.success) ? (
                    <React.Fragment>
                    <PaymentCards data={data.paymentData} isLoading={isLoading}/>
                    <PaymentTable data={data.paymentData}/>
                    </React.Fragment>
                ) : null
              }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )
     }
  }

  
  export default PaymentCard
  