import {
    displayToast
} from "./displayToast";

const mobileNumberValidation = (val) => {
    let mobileNoRegex = /^[6-9]\d{9}$/;
    return mobileNoRegex.test(val);
}

const otpValidation = (val) => {
    let otpRegex = /^\d{6}$/;
    return otpRegex.test(val);
}

const isNotNullOrEmpty = (val) => {
    return val !== undefined && val !== null && val !== "";
}

const numberValidation = (val, fieldName) => {
    let numberRegex = /^[0-9]*$/;
    let isValid = numberRegex.test(val);
    if (!isValid) {
        displayToast({
            toastMsg: `Only numbers allowed in ${fieldName}`,
            toastType: 'error'
        });
    }
    return isValid;
}

const decimalValidation = (val, fieldName) => {
    let numberRegex = /^\d*$/;
    // let isValid = numberRegex.test(val);
    let isValid = !isNaN(val);
    if (!isValid) {
        displayToast({
            toastMsg: `Only numbers allowed in ${fieldName}`,
            toastType: 'error'
        });
    }
    return isValid;
}

const inputValidation = (val, fieldName) => {
    if (!isNotNullOrEmpty(val)) {
        displayToast({
            toastMsg: `Please enter valid ${fieldName}`,
            toastType: 'error'
        });
        return false;
    }
    return true;
}


const chequeValidation = (val, fieldName) => {
    if (!isNotNullOrEmpty(val)) {
        displayToast({
            toastMsg: `Please enter valid ${fieldName}`,
            toastType: 'error'
        });
        return false;
    }else if(val.length !== 6){
        displayToast({
            toastMsg: `Cheque number should be 6 digit long`,
            toastType: 'error'
        });
        return false; 
    }
    return true;
}

const linkValidation = (val, fieldName) => {
    let regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    if (isNotNullOrEmpty(val)) {
        if (val.match(regex)) {
            return true;
        }
        displayToast({
            toastMsg: `Please enter valid ${fieldName} link`,
            toastType: 'error'
        });
        return false;
    } else {
        displayToast({
            toastMsg: `Please enter valid ${fieldName} link`,
            toastType: 'error'
        });
        return false;
    }
}

const emailValidation = (val, fieldName) => {
    if (inputValidation(val, 'email')) {
        var regEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;

        if (!val.match(regEmail)) {
            displayToast({
                toastMsg: 'Please Enter a Valid Email',
                toastType: 'error'
            });
            return false;
        } else if (val.length > 254) {
            displayToast({
                toastMsg: 'Email should not be more than 254 characters',
                toastType: 'error'
            });
        } else {
            return true;
        }
    } else {
        return false
    }
}

export {
    mobileNumberValidation,
    otpValidation,
    isNotNullOrEmpty,
    numberValidation,
    inputValidation,
    decimalValidation,
    linkValidation,
    emailValidation,
    chequeValidation
};