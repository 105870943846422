import React, {useReducer} from 'react'
import {Grid, makeStyles, TextField, Button, CircularProgress} from '@material-ui/core';
import {displayToast} from '../utils/displayToast';
import SingleDatePicker from './SingleDatePicker';
import SendIcon from '@material-ui/icons/Send';
import {numberValidation, isNotNullOrEmpty, inputValidation, decimalValidation, chequeValidation} from '../utils/validations';
import { URLS } from '../utils/routes';
import { fetchApi } from '../utils/hitApi';
import {useMutation} from 'react-query';
import { useHistory } from "react-router-dom";
import { debounce, getServerDate } from '../utils/functions';
import {DebounceInput} from 'react-debounce-input';

let isEditForm = false, paymentId = -1;
const currentDate = getServerDate(new Date());

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: '25ch'
      marginLeft: 20
    }
  },
  form: {
    width: '90%'
  }
}));

const initialState = {
  amount: "",
  amountError: false,
  comment: "",
  referenceId : "",
  chequeNumber : "",
  bankName : "",
  dueDate : currentDate,
  chequeDate : currentDate,
  disableButton : false,
  bAmount : 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'AMOUNT':
      return {
        ...state,
        amount: action.amount
      }

    case 'AMOUNT_ERROR':
      return {
        ...state,
        amountError: !state.amountError
      }

    case 'CHANGE_FIELDS':
      return {
        ...state,
        [action.fieldType]: action.value
      }

      case 'DISABLE_BUTTON':
        return {
          ...state,
          disableButton : !state.disableButton
        }

        case 'INIT_FIELDS':
          return {
            // ...state,
            ...action.fields
          }

          case 'RESET':
            return initialState;

    default:
      return state;
  }
}

function PaymentForm({
  isLoading,
  data = {},
  paymentMethods,
  value: activeTab,
  affiliateUserInvoiceId,
  status : pageStatus
}) {


  const classes = useStyles();
  let balanceAmount = 0, paidAmount = 0;

  if (data && data.affiliateUserPayment) {
    balanceAmount = data.affiliateUserPayment.balanceAmount;
    paidAmount = data.affiliateUserPayment.paidAmount;
  }

  if (balanceAmount) {
    balanceAmount = parseFloat(balanceAmount);
    paidAmount = parseFloat(paidAmount);
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    if(data && data.affiliateUserPayment){
      
      let {paymentMethodId, bankName, chequeDate, chequeNumber, paidAmount, referenceId, comment, id, dueDate} = data.affiliateUserPayment;
      if(isNotNullOrEmpty(paymentMethodId) && isNotNullOrEmpty(paidAmount)){
        isEditForm = true;
        paymentId = id;
        dispatch({
          fields : {
            bankName,
            amount : parseFloat(paidAmount),
            chequeDate,
            chequeNumber,
            comment,
            referenceId,
            dueDate,
            // amount: "",
            amountError: false,
            disableButton : false,
            bAmount : data.affiliateUserPayment.balanceAmount
          }, 
        type : 'INIT_FIELDS'
        });
      }else{
        isEditForm = false;
        dispatch({type : 'RESET', bAmount : data.affiliateUserPayment.balanceAmount});
      }
   }
  }, [data, data?.affiliateUserPayment]);

  const submitData = async({body}) =>{
    const url = isEditForm ? URLS.UPDATE_PAYMENT : URLS.INSERT_PAYMENT;
    const res = await fetchApi({
      url ,
      // Adding method type
      method: "POST",
      // Adding body or contents to send
      body,
    });
    return res;
  }

  const {amount, comment, amountError, bankName, chequeNumber, referenceId, dueDate, chequeDate, disableButton, bAmount} = state;

  const [mutate, { isLoading : formLoading, isError, isSuccess, status, data : formResponse, error, reset }] = useMutation(submitData)

  let history = useHistory();

  let compareValue = pageStatus === 'INSERT' ? balanceAmount : (balanceAmount + paidAmount);

  const changeAmount = (e) => {
    let value = e.target.value;
    // let compareValue = isEditForm ? (balanceAmount + parseFloat(data.affiliateUserPayment.paidAmount)) : balanceAmount;
      if (numberValidation(value, 'amount')) {
        if(value <= compareValue){
          dispatch({type: 'AMOUNT', amount: value});
        } else {
        displayToast({toastMsg: `Please enter valid amount less than or equal to ${compareValue}`, toastType: 'error'});
      }
    }
 }

  // const handler = React.useCallback(debounce(changeAmount, 2000), []);

  // const changeHandler = (e) =>{
  //   let value = e.target.value;
  //   handler(value);
  // }

  const changeVal = (e, type) => {
    let value = e.target.value;
    if(type === 'chequeNumber' && value.length > 6){
      
    }else{
      dispatch({type: 'CHANGE_FIELDS', fieldType: type, value});
    }
  }

  const changeDate = React.useCallback(
    (val,type) => {
      dispatch({type: 'CHANGE_FIELDS', fieldType: type, value : val});
    },
    [],
  );

  const activeTabName = paymentMethods[activeTab].name;

  const submitPayment = async(e) =>{
    e.preventDefault();
    // dispatch({type : 'DISABLE_BUTTON'});
    let paymentMethodId = paymentMethods[activeTab].id,
        paymentMethod = activeTabName.toUpperCase();

      if(!inputValidation(amount, 'amount')){
        return false;
      }else if(!decimalValidation(amount, 'amount')){
        return false;
      }else if(amount > compareValue || amount <= 0){
        displayToast({toastMsg: `Payment amount should be between 0.1 - ${compareValue}`, toastType: 'error'});
        return false;
      }else if(paymentMethod === 'CHEQUE'){
        if(!inputValidation(bankName, 'bank name')){
          return false;
        }else if(!chequeValidation(chequeNumber, 'cheque number')){
            return false;
        } else if(!inputValidation(chequeDate, 'cheque date')){
          return false;
        }
      }else if(paymentMethod !== 'CHEQUE' && paymentMethod !== 'CASH'){
        if(!inputValidation(referenceId, 'transaction reference no.')){
          return false;
        }
      }

      let body = {
        paymentMethodId,
        bankName,
        chequeDate,
        chequeNumber,
        "paidAmount":amount,
        referenceId,
        comment,
        affiliateUserInvoiceId,
        dueDate,
        paymentId
      }

      if(isEditForm){
        body.affiliateUserInvoiceId = data.affiliateUserPayment.affiliateUserInvoiceId;
        body.paymentId = data.affiliateUserPayment.id;
      }

      if(paymentMethod === 'CHEQUE'){
          body.referenceId = null;
      }else if(paymentMethod !== 'CHEQUE'){
        body.chequeDate = null;
        body.chequeNumber = null;
        body.bankName = null;
        if(paymentMethod === 'CASH'){
          body.referenceId = null;
        }
      }

      try {
        let res = await mutate({ body });
        let {success, msg} = res;

        let toastType = 'success';
        if(success){
          reset();

          setTimeout(() => {
            history.push('/manage-payments');
          }, 100);

        }else{
          toastType = 'error';
        }
        displayToast({toastMsg: msg, toastType});
        // form submission successful
      } catch (error) {
        displayToast({toastMsg: error.message, toastType : 'error'});
        // Uh oh, something went wrong
      }
  }

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={submitPayment}>
      <Grid container spacing={4} className={classes.form}>
        <Grid item md={4} xs={12}>
          <TextField
            error={amountError}
            id="amount"
            label="Amount"
            value={amount}
            type="number"
            required
            fullWidth
            variant="outlined"
            onChange={changeAmount}
            helperText={`Max amount : ${compareValue}`}/>
        </Grid>

        <Grid item md={4} xs={12}>
          <SingleDatePicker label="Due Date" helperText="When'll you do rest of the payment?" type="dueDate" changeDate={changeDate}/>
        </Grid>

        {paymentMethods[activeTab].name === 'Cheque' && (
          <React.Fragment>
            <Grid item md={4} xs={12}>
              <TextField
                id="bank-name"
                label="Bank Name"
                required
                fullWidth
                variant="outlined"
                value={bankName}
                onChange={(e) => changeVal(e, 'bankName')}
                />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                id="cheque-number"
                label="Cheque Number"
                required
                fullWidth
                variant="outlined"
                value={chequeNumber}
                onChange={(e) => changeVal(e, 'chequeNumber')}
                />
            </Grid>
            <Grid item md={4} xs={12}>
              <SingleDatePicker label="Cheque Date" type="chequeDate" changeDate={changeDate}/>
            </Grid>
          </React.Fragment>
        )}

        {(activeTabName !== 'Cheque' && activeTabName !== 'Cash') && (
          <Grid item md={4} xs={12}>
            <TextField
              id="ref-number"
              label="Transaction Reference No"
              required
              fullWidth
              variant="outlined"
              value={referenceId}
              onChange={(e) => changeVal(e, 'referenceId')}
              />
          </Grid>
        )}

        <Grid item xs={6}>
          <TextField
            id="Comments"
            label="Comments"
            fullWidth
            multiline
            rowsMax={2}
            value={comment}
            variant="outlined"
            onChange={(e) => changeVal(e, 'comment')}/>
        </Grid>

        <Grid item xs={12} className="center-align">
          <Button variant="contained" color="primary" 
            onClick={submitPayment}
            disabled={formLoading || isSuccess}
          type="submit"
          endIcon={<SendIcon/>}
          >
          {formLoading ? <CircularProgress/> : 'Submit Payment'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default PaymentForm
