// const BASE_PATH = 'http://192.168.0.101:9001/';
// const BASE_PATH = 'http://192.168.0.108:9001/';
// const BASE_PATH = 'http://192.160.0.103:9001/';

const URLS = {
    'GET_OTP_TO_LOGIN': 'affiliate/panel-login',
    'VERIFY_OTP_TO_LOGIN': 'affiliate/panel-login-verify',
    FETCH_CATEGORIES : 'product/category/fetch-all-catagories-for-affiliate',
    FETCH_PRODUCTS : 'affiliate/fetch-product-info-for-affiliate-panel',
    FETCH_AFFILIATE_USERS : 'affiliate/fetch-affiliate-user-list',
    CHANGE_AFFILIATE_STATUS : 'affiliate/update-status',
    FETCH_ALL_AFFILIATES : 'affiliate/fetch-affiliate-users',
    FETCH_PAYMENT_LIST : 'affiliate/fetch-affiliate-invoice-list',
    FETCH_PAYMENT_DETAILS : 'affiliate/fetch-payment-details',
    GET_PAYMENT_METHODS : 'get-payment-methods',
    INSERT_PAYMENT : 'affiliate/insert-payment',
    UPDATE_PAYMENT : 'affiliate/update-payment',
    FETCH_PAYMENTS_BY_INVOICE : 'affiliate/fetch-payment-list',
    FETCH_PAYMENT_DETAIL_TO_UPDATE : 'affiliate/get-payment-details',
    DELETE_PAYMENT : 'affiliate/delete-payment',
    FETCH_PAYMENT_HISTORY : 'affiliate/fetch-payment-history',
    FETCH_DASHBOARD_DATA : 'affiliate/fetch-affiliate-dashboard-data',
    UPDATE_PROFILE : 'affiliate/update-affiliate-user-profile',
    GET_OTP_TO_UPDATE_MOBILE_NO : 'affiliate/request-to-update-mobile-no',
    VERIFY_MOBILE_NO_TO_UPDATE : 'affiliate/verify-update-mobile-no-request',
    CHANGE_USER_ACTIVE_STATUS : 'affiliate/update-active-inactive-status',
    FETCH_ALL_COUPONS : 'affiliate/fetch-coupons-reports-for-affiliate-users',
    FETCH_SINGLE_COUPON_DATA : 'cart/fetch-coupon-selected-style'
}

export {URLS};