import React from 'react'

import { useTable, usePagination} from 'react-table'

import {TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePaginationActions from './TablePaginationActions';
import TableToolbar from './TableToolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Empty from '../PlaceHolders/Empty';
import { isNotNullOrEmpty } from '../../utils/validations';
let enableChangeFirstTime = false;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop : '30px'
  },
  container: {
    maxHeight: '70vh',
  },
  primary : {
    backgroundColor : theme.palette.primary.light
  },
  secondary : {
    backgroundColor : theme.palette.secondaryError.light
  },
  mainTxt : {
    color : theme.palette.primary.main,
    fontSize : 18
  }
}));

function TableComponent({data : tableData = {}, columns, fetchData = null, loading, 
  pageCount: controlledPageCount,userData, brandName, changeKey = null, changeKeyOne = null, initialPage, 
  currentPageNumber, changeSearchedTerm, modifyTableData,
  tableTitle, subTitle,
  manualPagination = false, manualSearch = false, listName = "", showSearch, removeList = [], currentPageSize = 10, isFetching,
  changeColumnColor = false, columnColorKey, hideSearch = false
}){
  // console.log('[removeList] : ', removeList);
  // console.log('in TableComponent : ', tableData);
  let {totalRecords = 0} = tableData;
  let list = tableData[listName] || [];

  // console.log(tableData[listName], ' list in TableComponent');

  controlledPageCount = parseInt(totalRecords);

  if(removeList && removeList.length > 0){
    controlledPageCount -= removeList.length;
  }
  const classes = useStyles();

  let data = modifyTableData(list, userData, brandName, currentPageNumber);
  
  const newRemoveList = [...removeList];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    // Get the state from the instance
    state: { pageIndex, pageSize =  currentPageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1, pageSize : currentPageSize }, // Pass our hoisted table state
      manualPagination, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    // !manualSearch && useGlobalFilter,
    usePagination
  )

   // Listen for changes in pagination and use the state to fetch our new data
   React.useEffect(() => { 
  //  console.log('changeKey changed');
   if(enableChangeFirstTime === false){
    enableChangeFirstTime = true;
   }else{
    // console.log('hit for changeKey change');
    gotoPage(1);
   }
  }, [changeKey, changeKeyOne]);
  
  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => { 
    if(pageIndex >= 1){
      // console.log('fetching data with ', pageIndex);
      fetchHandler(pageIndex, pageSize);
      // fetchData(pageIndex, pageSize);
    }
  }, [pageIndex, pageSize, changeKey, changeKeyOne])

  function fetchHandler(pageIndex, pageSize){
    if(!isFetching && !loading){
      fetchData(pageIndex, pageSize);
    }
  }

  const handleChangePage = (event, newPage) => {
    // debugger;
    //console.log('[handleChangePage] newPage : ', newPage);
    // fetchData(newPage + 1, pageSize);
    gotoPage(newPage + 1)
  };

  const handleChangeRowsPerPage = (event) => {
    //console.log('in handleChangeRowsPerPage');
    // fetchData(parseInt(event.target.value, 10),1);
    setPageSize(Number(event.target.value));
    // gotoPage(1);
  };

  const handleSearchFilter = React.useCallback(
    (value) => {
      changeSearchedTerm(value);
      gotoPage(1)
    },
    [],
  )
    // console.log({headerGroups});
  return (
    <React.Fragment>
      {(loading || isFetching) && <LinearProgress />}
    
      <Paper className={classes.root}>
      <TableToolbar tableTitle={tableTitle} subTitle={subTitle} 
      // handleSearchFilter={manualSearch ?  handleSearchFilter : setGlobalFilter}
       handleSearchFilter={handleSearchFilter}
       hideSearch = {hideSearch}
      />
      <TableContainer className={classes.container}>
      <Table aria-label="simple table" {...getTableProps()} stickyHeader={true}>
      <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell align="center" {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {column.canBeFiltered  && <span className={classes.mainTxt}>*</span>}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>

          {page.length === 0 && (<TableRow>
            <TableCell align="center" colSpan={headerGroups[0].headers.length}>
                <Empty/>
            </TableCell>
          </TableRow>)}

          {page.map((row, i) => {
            let showRow = true;
            if(newRemoveList && newRemoveList.length > 0){
              let {original : {id}} = row;
                    let index = newRemoveList.findIndex((item) => item === id);
                    if(isNotNullOrEmpty(index) && index !== -1){
                      showRow = false;
                      newRemoveList.splice(index,1);
                    }
              }
              if(showRow){
                    prepareRow(row)
              }else{
                    return null
              }
              
              let columnColorClass = "";

              if(changeColumnColor === true){
                let {original} = row;

                let colorKey = original[columnColorKey];
                if(colorKey === false){
                  columnColorClass = classes.secondary;
                }
              }

            return (
              <TableRow {...row.getRowProps()} className={columnColorClass}>
                {row.cells.map(cell => {
                  return <TableCell align={cell?.column?.Header === "Details" ? 'left' : "center"}  {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
        </TableContainer>
        <TableFooter>
          <TableRow>
            <TablePagination
              // rowsPerPageOptions={[2, 5, 10, 25, { label: 'All', value: -1 }]}
              rowsPerPageOptions={[currentPageSize]}
              // colSpan={3}
              count={controlledPageCount}
              rowsPerPage={currentPageSize}
              page={pageIndex - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Paper>
      </React.Fragment>
  )
}

export default TableComponent;