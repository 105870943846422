import React from 'react'
import Table from '../components/Tables/Table'
import BasicTable from '../components/Tables/BasicTable'
import TabsPanel from '../components/Tabs/TabsPanel'

const TABS_HEADER = [
  {
    id: 1,
    title: 'Approved'
  }, {
    id: 2,
    title: 'Pending Requests'
  }, {
    id: 3,
    title: 'Rejected'
  }
];

function ManageAffiliate() {
  return (
    <React.Fragment>
      <TabsPanel tabsHeader={TABS_HEADER} tabsData={TABS_HEADER}/>
      {/* <Table/> */}
      <BasicTable/>
    </React.Fragment>
  )
}

export default ManageAffiliate
