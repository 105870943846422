import React from 'react'
import { Card } from '@material-ui/core';

// makeStyles, Icon
// const useStyles = makeStyles({
//   root: {
//     minWidth: '25vw',
//     padding : 20,
//     overflow : 'visible'
//   }
// });

function CardBox(props) {
  // const classes = useStyles();

    return (
        <Card elevation={2} className={props.className || ""}>
          {props.children}  
        </Card>
    )
}

export default CardBox
