import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Box} from '@material-ui/core';
import PaymentForm from '../PaymentForm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    // height: 224
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width : '20%'
  },
  selected: {
    backgroundColor: theme.palette.primary.light
  }
}));

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`};
}

function VerticalTabs({
  data = {},
  paymentData = {},
  affiliateUserInvoiceId,
  status
}) {
  const {paymentMethods} = data;
  const classes = useStyles();

  // console.log({paymentData}, 'paymentData in VerticalTabs');

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if(paymentData && paymentData.affiliateUserPayment && paymentData.affiliateUserPayment.paymentMethodId){
      let index = paymentMethods.findIndex(item => item.id == paymentData.affiliateUserPayment.paymentMethodId);
      // console.log({index});
      setValue(index);
    }else{
      setValue(0);
    }
  }, [paymentData, paymentMethods])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Payment methods"
        className={classes.tabs}
        indicatorColor="primary">
        {paymentMethods.map(item => {
          let {id, name} = item;
          return <Tab key={id} label={name} {...a11yProps(id)}/>
        })}
      </Tabs>
      <PaymentForm 
      status={status} 
      data={paymentData} 
      value={value} paymentMethods={paymentMethods} 
      affiliateUserInvoiceId={affiliateUserInvoiceId}/>
      {/* <TabPanel value={value} index={0}>
        Item One
      </TabPanel> */}
    </div>
  )
}

export default VerticalTabs
