import React from 'react'
import {Link} from "react-router-dom";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";

import clsx from 'clsx';
import {useTheme} from '@material-ui/core/styles';
import {APP_ROUTES} from '../../constants/RouteUtils';
import {useStyles} from './Sidebar.Styles';

import {useLocation} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { AuthContext } from '../../context/Auth';
import Logout from './Logout';
import { useBrand } from '../../hooks/useBrand';

import FaceIcon from '@material-ui/icons/Face';

function Sidebar() {

  const {isAdmin, userData} = React.useContext(AuthContext);

  const location = useLocation();

  let {pathname, search} = location;

  const [brandName] = useBrand();

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(!isMobile);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const PageTitle = pathname.replace('/',"").replace('-'," ").toUpperCase();

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}>
        <Toolbar className={classes.topToolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
            [classes.hide]: open
          })}>
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" noWrap>
            {/* Affiliate Marketing */}
            {PageTitle}
            {/* {!!pathname ? pathname.replace('/', "").split('-').toUpperCase() : 'Affiliate Marketing'} */}
          </Typography>
          <Logout isIcon={true}/>
          {/* <ExitToAppIcon onClick={logout}/> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
        classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}>
        <div className="sidebar-content">
          <div className={classes.toolbar}>
            <h3>{brandName} Cosmetics</h3>
            <IconButton onClick={handleDrawerClose} className={clsx(classes.whiteFont)}>
              {theme.direction === "rtl"
                ? (<ChevronRightIcon/>)
                : (<ChevronLeftIcon/>)}
            </IconButton>
          </div>
          <Divider/>

          <List>
          {!isAdmin && (
            
            <ListItem>

              <ListItemIcon className={clsx(classes.whiteFont)}>
                <FaceIcon/>
              </ListItemIcon>
              <ListItemText primary={userData.name} className={clsx(classes.whiteFont)}/>

            </ListItem>
          )}
            {APP_ROUTES.map((item, index) => {

              let {
                id,
                title,
                path,
                isAdminPath,
                Icon,
                isInfluencerPath
              } = item;

              let isActive = path === (pathname + search);

              if((isAdminPath === true && !isAdmin) || (isInfluencerPath === true && isAdmin)){
                return null;
              }else{
                return (
                  <Link to={path} className="no-link-ui" key={id}>
                  <Tooltip title={title}>
                      <ListItem button selected={isActive} className={isActive ? clsx(classes.selected) : ""}>
                        <ListItemIcon className={clsx(classes.whiteFont)}>
                          <Icon/>
                        </ListItemIcon>
                        <ListItemText primary={title} 
                        className={clsx(classes.whiteFont)}/>
                      </ListItem>
                    </Tooltip>
                  </Link>
                )
              }
            })}
          </List>
        </div>
        <div className="sidebar-bg"></div>
      </Drawer>
    </React.Fragment>
  )
}

export default Sidebar
