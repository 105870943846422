import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    // maxWidth: '90vw',
    // overflowX : 'scroll'
  },
  tableAaction : {
    display : 'flex',
    justifyContent : 'space-evenly'
  }
});

function createData(username,name, mobile, email, earning, commission) {
  return {  username, name, mobile, email, earning, commission };
}

const rows = [
  createData('john01','John Doe', '1234567891', 'a@a.com', 2400, 1200)
];

export default function BasicTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" stickyHeader={true}>
        <TableHead>
          <TableRow>
          <TableCell align="right">Sr. No</TableCell>
            {/* <TableCell>Username</TableCell> */}
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Mobile</TableCell>
            <TableCell align="right">E-mail</TableCell>
            <TableCell align="right">Earnings</TableCell>
            <TableCell align="right">Commission</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.name}>
            <TableCell align="right">{index + 1}</TableCell>
              {/* <TableCell align="right">{row.username}</TableCell> */}
              <TableCell align="right">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.mobile}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">&#8377;{row.earning}</TableCell>
              <TableCell align="right">&#8377;{row.commission}</TableCell>
              <TableCell align="center" className={classes.tableAaction}>
                <Button size="small" variant="outlined" color="primary">
                  Approve
                </Button>
                <Button size="small" variant="outlined" color="secondary">
                  Reject
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
